import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import "./Main.css";

import Home from './home/Home';
import About from './about/About';
import Cliente from './client/Client';
import Contato from './contact/Contact';
import Wpp from './wpp/Wpp';
import Planos from './Planos/Planos';
import FormConfirmation from './client/formconfimation/FormConfirmation';
import NotFound from './NotFound/NotFound';
import Aplicativo from './Aplicativo/Aplicativo';
import ScrollToTop from '../../hooks/ScrollToTop';
import Consultas from './Consultas/Consultas';
import Redes from './Rede/Rede';
import Telemedicina from './InfoPage/Telemedicina/Telemedicina';
import CartaoBeneficiario from './InfoPage/Cartao/Cartao';
import Autorizacao from './InfoPage/Autorizacao/Autorizacao';

const Main = () => {
  const location = useLocation();

  const shouldRenderWpp = location.pathname !== '/consultas';

  return (
    <main className='main'>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sobre" element={<About />} />
        <Route path="/cliente" element={<Cliente />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/planos" element={<Planos />} />
        <Route path="/aplicativo" element={<Aplicativo />} />
        <Route path="/consultas" element={<Consultas />} />
        <Route path="/rede" element={<Redes />} />
        <Route path="/telemedicina" element={<Telemedicina />} />
        <Route path="/cartao-beneficiario" element={<CartaoBeneficiario />} />
        <Route path="/autorizacao" element={<Autorizacao />} />
        <Route path="/confirmacao" element={<FormConfirmation />} />
        <Route path="/about" element={<Navigate to="/sobre" />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {shouldRenderWpp && <Wpp />}
    </main>
  );
};

export default Main;
