const RedesGuaruja = [

    {
        especialidade: "Acupuntura",
        estabelecimentos: [
            {
                nome: "CEFIG",
                endereco: "Rua Buenos Aires, 40, Vila Maia, Guarujá / SP - CEP 11410-010",
                telefones: ["(13) 3025-1018"],
            }
        ],
    },
    {
        especialidade: "Anatomia Patológica",
        estabelecimentos: [
            {
                nome: "Clinasma Laboratório",
                endereco: "Rua Benjamin Constant, 375, Centro, Guarujá / SP - CEP 11410-270",
                telefones: ["(13) 3355-8239", "(13) 3355-8935"],
            },
            {
                nome: "Laboratório Gonzaga",
                endereco: "Rua Santo Amaro, 320, Centro, Guarujá / SP - CEP 11410-070",
                telefones: ["(13) 3395-8533"],
            },
            {
                nome: "Vitlab Litoral",
                endereco: "Avenida Puglisi, 815, Centro, Guarujá / SP - CEP 11410-002",
                telefones: ["(13) 3395-6309"],
            },
        ],
    },
    {
        especialidade: "Cardiologia",
        estabelecimentos: [
            {
                nome: "Centro Médico Cavalcante - Guarujá",
                endereco: "Rua Montenegro, 18, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Centro Médico do Guarujá",
                endereco: "Rua Rio Grande do Sul, 43, Jardim Cunhambebe (Vicente de Carvalho), Guarujá / SP - CEP 11450-130",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Hospital Guarujá",
                endereco: "Avenida Santos Dumont, 3651, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-002",
                telefones: ["(13) 3308-1720"],
            },

            {
                nome: "Neoclínica Guarujá",
                endereco: "Rua Buenos Aires, 320, Vila Maia, Guarujá / SP - CEP 11410-010",
                telefones: ["(13) 3355-3416"],
            },
            {
                nome: "Unita Class Saúde",
                endereco: "Avenida Santos Dumont, 264, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 3341-4114", "(13) 99746-1075"],
            },
        ],
    },
    {
        especialidade: "Cirurgia do Aparelho Digestivo",
        estabelecimentos: [
            {
                nome: "Centro Médico Cavalcante - Guarujá",
                endereco: "Rua Montenegro, 18, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3304-5401"],
            },
        ],
    },
    {
        especialidade: "Cirurgia Geral",
        estabelecimentos: [
            {
                nome: "PTCB Serviços Médicos",
                endereco: "Rua Oscar Sampaio, 65, Jardim Santense (Vicente de Carvalho), Guarujá / SP - CEP 11450-370",
                telefones: ["(13) 3395-7350", "(13) 99607-9317"],
            },
        ],
    },
    {
        especialidade: "Cirurgia Geral Odontológica",
        estabelecimentos: [
            {
                nome: "Naso",
                endereco: "Rua Quintino Bocaiúva, 461, Centro, Guarujá / SP - CEP 11410-030",
                telefones: ["(13) 3355-8702", "(13) 97421-0838"],
            },
        ],
    },
    {
        especialidade: "Cirurgia Plástica",
        estabelecimentos: [
            {
                nome: "Hospital Guarujá",
                endereco: "Avenida Santos Dumont, 3651, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-002",
                telefones: ["(13) 3308-1720"],
            },
        ],
    },
    {
        especialidade: "Cirurgia Vascular",
        estabelecimentos: [
            {
                nome: "Centro Médico Cavalcante - Guarujá",
                endereco: "Rua Montenegro, 18, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Hospital Guarujá",
                endereco: "Avenida Santos Dumont, 3651, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-002",
                telefones: ["(13) 3308-1720"],
            },
        ],
    },
    {
        especialidade: "Citopatologia",
        estabelecimentos: [
            {
                nome: "Clinasma Laboratório",
                endereco: "Rua Benjamin Constant, 375, Centro, Guarujá / SP - CEP 11410-270",
                telefones: ["(13) 3355-8239", "(13) 3355-8935"],
            },
            {
                nome: "Laboratório Gonzaga",
                endereco: "Rua Santo Amaro, 320, Centro, Guarujá / SP - CEP 11410-070",
                telefones: ["(13) 3395-8533"],
            },
            {
                nome: "Vitlab Litoral",
                endereco: "Avenida Puglisi, 815, Centro, Guarujá / SP - CEP 11410-002",
                telefones: ["(13) 3395-6309"],
            },
        ],
    },
    {
        especialidade: "Clínica Geral Odontológica",
        estabelecimentos: [
            {
                nome: "Atelie da Saúde",
                endereco: "Rua Buenos Aires, 611, Vila Maia, Guarujá / SP - CEP 11410-010",
                telefones: ["(13) 99165-4416"],
            },
            {
                nome: "Bianca Castro Lopes",
                endereco: "Rua Adelino Cardoso, 15, Vila Santa Rosa, Guarujá / SP - CEP 11431-040",
                telefones: ["(13) 99652-9580"],
            },
            {
                nome: "Carita Seabra Afonso",
                endereco: "Avenida Adhemar de Barros, 1347, Vila Santa Rosa, Guarujá / SP - CEP 11430-003",
                telefones: ["(13) 99772-9140"],
            },
            {
                nome: "Centro Odontológico Guarujá - COG",
                endereco: "Rua Montenegro, 80, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3355-8412"],
            },
            {
                nome: "Centro Odontológico Pradent",
                endereco: "Alameda das Margaridas, 777, Jardim Primavera, Guarujá / SP - CEP 11432-240",
                telefones: ["(13) 3383-3094", "(13) 98855-2216"],
            },
            {
                nome: "Clovis de Moura Garcia Neto",
                endereco: "Avenida Adhemar de Barros, 1347, Vila Santo Antônio, Guarujá / SP - CEP 11430-000",
                telefones: ["(13) 3358-2867", "(13) 98111-4408"],
            },
            {
                nome: "Clínica Juliana Bianchi",
                endereco: "Rua Ettore Rangoni, 06, Vila Maia, Guarujá / SP - CEP 11410-050",
                telefones: ["(13) 99646-5224"],
            },
            {
                nome: "Mariana Macedo Teixeira",
                endereco: "Avenida Arthur Costa Filho, 24, Vila Maia, Guarujá / SP - CEP 11410-080",
                telefones: ["(13) 99142-0614"],
            },
            {
                nome: "Michele Cristine Batista do Nascimento",
                endereco: "Rua Herman Stadie, 48, Jardim Santa Maria, Guarujá / SP - CEP 11432-210",
                telefones: ["(13) 99198-9542"],
            },
            {
                nome: "Naline Guimaraes Mazuchi",
                endereco: "Rua Montenegro, 169, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 99644-1314"],
            },
            {
                nome: "Naso",
                endereco: "Rua Quintino Bocaiúva, 461, Centro, Guarujá / SP - CEP 11410-030",
                telefones: ["(13) 3355-8702", "(13) 97421-0838"],
            },
            {
                nome: "Paula Bocarde Nagaishi",
                endereco: "Avenida Antenor Pimentel, 1536, Morrinhos, Guarujá / SP - CEP 11495-000",
                telefones: ["(13) 99718-3281"],
            },
            {
                nome: "Suzy De Paula Correa",
                endereco: "Rua Doutor Guilherme Guinle, 469, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11450-260",
                telefones: ["(13) 3326-5177", "(13) 98109-1507"],
            },
            {
                nome: "Tradeodonto Serviços Odontológicos",
                endereco: "Rua Tunekichi Matsumoto, 45, Jardim Santa Maria, Guarujá / SP - CEP 11432-220",
                telefones: ["(13) 3384-1639", "(13) 99770-1345"],
            },
        ]
    },
    {
        especialidade: "Clínica Médica",
        estabelecimentos: [
            {
                nome: "Med Saúde",
                endereco: "Rua Montenegro, 80, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3379-2019"],
            },
            {
                nome: "Centro Clínico Frei Galvão",
                endereco: "Avenida Santos Dumont, 694, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Centro Médico Cavalcante - Guarujá",
                endereco: "Rua Montenegro, 18, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Centro Médico do Guarujá",
                endereco: "Rua Rio Grande do Sul, 43, Jardim Cunhambebe (Vicente de Carvalho), Guarujá / SP - CEP 11450-130",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Clinasma Laboratório",
                endereco: "Rua Benjamin Constant, 375, Centro, Guarujá / SP - CEP 11410-270",
                telefones: ["(13) 3355-8239", "(13) 3355-8935"],
            },
            {
                nome: "Hospital Guarujá",
                endereco: "Avenida Santos Dumont, 3651, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-002",
                telefones: ["(13) 3308-1720"],
            },
            {
                nome: "Medlife",
                endereco: "Rua Washington, 42, Vila Maia, Guarujá / SP - CEP 11410-150",
                telefones: ["(13) 3382-6742"],
            },
            {
                nome: "PTCB Serviços Médicos",
                endereco: "Rua Oscar Sampaio, 65, Jardim Santense (Vicente de Carvalho), Guarujá / SP - CEP 11450-370",
                telefones: ["(13) 3395-7350", "(13) 99607-9317"],
            },
            {
                nome: "Unita Class Saúde",
                endereco: "Avenida Santos Dumont, 264, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 3341-4114", "(13) 99746-1075"],
            },
        ],
    },
    {
        especialidade: "Coloproctologia",
        estabelecimentos: [
            {
                nome: "Centro Médico Cavalcante - Guarujá",
                endereco: "Rua Montenegro, 18, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "PTCB Serviços Médicos",
                endereco: "Rua Oscar Sampaio, 65, Jardim Santense (Vicente de Carvalho), Guarujá / SP - CEP 11450-370",
                telefones: ["(13) 3395-7350", "(13) 99607-9317"],
            },
        ],
    },
    {
        especialidade: "Densitometria Óssea",
        estabelecimentos: [
            {
                nome: "CDI - Centro Integrado de Diagnosticos",
                endereco: "Avenida Adhemar de Barros, 210, Vila Santo Antônio, Guarujá / SP - CEP 11430-000",
                telefones: ["(13) 3387-1717", "(13) 99659-2777"],
            },
            {
                nome: "Clínica Radiológica do Guarujá",
                endereco: "Avenida Dep Emílio Carlos, 160, Vila Maia, Guarujá / SP - CEP 11410-140",
                telefones: ["(13) 3387-5733"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Washington, 164, Vila Maia, Guarujá / SP - CEP 11410-150",
                telefones: ["(13) 3202-1990"],
            },
        ],
    },
    {
        especialidade: "Dentística",
        estabelecimentos: [
            {
                nome: "Atelie da Saúde",
                endereco: "Rua Buenos Aires, 611, Vila Maia, Guarujá / SP - CEP 11410-010",
                telefones: ["(13) 99165-4416"],
            },
            {
                nome: "Bianca Castro Lopes",
                endereco: "Rua Adelino Cardoso, 15, Vila Santa Rosa, Guarujá / SP - CEP 11431-040",
                telefones: ["(13) 99652-9580"],
            },
            {
                nome: "Bio Fx Radiologia Odontológica",
                endereco: "Avenida Thiago Ferreira, 912, Vila Alice (Vicente de Carvalho), Guarujá / SP - CEP 11450-002",
                telefones: ["(13) 3342-8282"],
            },
            {
                nome: "Carita Seabra Afonso",
                endereco: "Avenida Adhemar de Barros, 1347, Vila Santa Rosa, Guarujá / SP - CEP 11430-003",
                telefones: ["(13) 99772-9140"],
            },
            {
                nome: "Centro Odontológico Guarujá - COG",
                endereco: "Rua Montenegro, 80, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3355-8412"],
            },
            {
                nome: "Centro Odontológico Pradent",
                endereco: "Alameda das Margaridas, 777, Jardim Primavera, Guarujá / SP - CEP 11432-240",
                telefones: ["(13) 3383-3094", "(13) 98855-2216"],
            },
            {
                nome: "Clovis de Moura Garcia Neto",
                endereco: "Avenida Adhemar de Barros, 1347, Vila Santo Antônio, Guarujá / SP - CEP 11430-000",
                telefones: ["(13) 3358-2867", "(13) 98111-4408"],
            },
            {
                nome: "Clínica Juliana Bianchi",
                endereco: "Rua Ettore Rangoni, 06, Vila Maia, Guarujá / SP - CEP 11410-050",
                telefones: ["(13) 99646-5224"],
            },
            {
                nome: "Michele Cristine Batista do Nascimento",
                endereco: "Rua Herman Stadie, 48, Jardim Santa Maria, Guarujá / SP - CEP 11432-210",
                telefones: ["(13) 99198-9542"],
            },
            {
                nome: "Naline Guimaraes Mazuchi",
                endereco: "Rua Montenegro, 169, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 99644-1314"],
            },
            {
                nome: "Naso",
                endereco: "Rua Quintino Bocaiúva, 461, Centro, Guarujá / SP - CEP 11410-030",
                telefones: ["(13) 3355-8702", "(13) 97421-0838"],
            },
            {
                nome: "Paula Bocarde Nagaishi",
                endereco: "Avenida Antenor Pimentel, 1536, Morrinhos, Guarujá / SP - CEP 11495-000",
                telefones: ["(13) 99718-3281"],
            },
            {
                nome: "Suzy De Paula Correa",
                endereco: "Rua Doutor Guilherme Guinle, 469, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11450-260",
                telefones: ["(13) 3326-5177", "(13) 98109-1507"],
            },
            {
                nome: "Tradeodonto Serviços Odontológicos",
                endereco: "Rua Tunekichi Matsumoto, 45, Jardim Santa Maria, Guarujá / SP - CEP 11432-220",
                telefones: ["(13) 3384-1639", "(13) 99770-1345"],
            },
            {
                nome: "Uniero",
                endereco: "Avenida Adhemar de Barros, 230, Vila Santo Antônio, Guarujá / SP - CEP 11430-000",
                telefones: ["(13) 3371-7677", "(13) 3383-4777", "(13) 99784-4777"],
            },
        ],
    },
    {
        especialidade: "Dermatologia",
        estabelecimentos: [
            {
                nome: "Med Saúde",
                endereco: "Rua Montenegro, 80, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3379-2019"],
            },
            {
                nome: "Centro Médico Cavalcante - Guarujá",
                endereco: "Rua Montenegro, 18, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Clínica Saúde Ideal",
                endereco: "Rua Rio Grande do Sul, 43, Jardim Cunhambebe (Vicente de Carvalho), Guarujá / SP - CEP 11450-130",
                telefones: ["(13) 3341-4321"],
            },
            {
                nome: "Hospital Guarujá",
                endereco: "Avenida Santos Dumont, 3651, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-002",
                telefones: ["(13) 3308-1720"],
            },
            {
                nome: "PTCB Serviços Médicos",
                endereco: "Rua Oscar Sampaio, 65, Jardim Santense (Vicente de Carvalho), Guarujá / SP - CEP 11450-370",
                telefones: ["(13) 3395-7350", "(13) 99607-9317"],
            },
            {
                nome: "Unita Class Saúde",
                endereco: "Avenida Santos Dumont, 264, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 3341-4114", "(13) 99746-1075"],
            },
        ],
    },
    {
        especialidade: "Ecografa Vascular com Doppler",
        estabelecimentos: [
            {
                nome: "Mega Imagem",
                endereco: "Rua Washington, 164, Vila Maia, Guarujá / SP - CEP 11410-150",
                telefones: ["(13) 3202-1990"],
            },
        ],
    },
    {
        especialidade: "Endocrinologia",
        estabelecimentos: [
            {
                nome: "Centro Médico Cavalcante - Guarujá",
                endereco: "Rua Montenegro, 18, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Centro Médico do Guarujá",
                endereco: "Rua Rio Grande do Sul, 43, Jardim Cunhambebe (Vicente de Carvalho), Guarujá / SP - CEP 11450-130",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Hospital Guarujá",
                endereco: "Avenida Santos Dumont, 3651, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-002",
                telefones: ["(13) 3308-1720"],
            },
        ],
    },
    {
        especialidade: "Endodontia",
        estabelecimentos: [
            {
                nome: "Centro Odontológico Guarujá - COG",
                endereco: "Rua Montenegro, 80, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3355-8412"],
            },
            {
                nome: "Clovis de Moura Garcia Neto",
                endereco: "Avenida Adhemar de Barros, 1347, Vila Santo Antônio, Guarujá / SP - CEP 11430-000",
                telefones: ["(13) 3358-2867", "(13) 98111-4408"],
            },
            {
                nome: "Miguel Angelo Nicotra",
                endereco: "Avenida Adhemar de Barros, 1347, Vila Santa Rosa, Guarujá / SP - CEP 11430-003",
                telefones: ["(13) 3358-1328"],
            },
            {
                nome: "Naso",
                endereco: "Rua Quintino Bocaiúva, 461, Centro, Guarujá / SP - CEP 11410-030",
                telefones: ["(13) 3355-8702", "(13) 97421-0838"],
            },
            {
                nome: "Tradeodonto Serviços Odontológicos",
                endereco: "Rua Tunekichi Matsumoto, 45, Jardim Santa Maria, Guarujá / SP - CEP 11432-220",
                telefones: ["(13) 3384-1639", "(13) 99770-1345"],
            },
        ],
    },
    {
        especialidade: "Endoscopia Digestiva",
        estabelecimentos: [
            {
                nome: "PTCB Serviços Médicos",
                endereco: "Rua Oscar Sampaio, 65, Jardim Santense (Vicente de Carvalho), Guarujá / SP - CEP 11450-370",
                telefones: ["(13) 3395-7350", "(13) 99607-9317"],
            },
        ],
    },
    {
        especialidade: "Exames",
        estabelecimentos: [
            {
                nome: "Unita Class Saúde",
                endereco: "Avenida Santos Dumont, 264, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 3341-4114", "(13) 99746-1075"],
            },
        ],
    },
    {
        especialidade: "Fisioterapia",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Leomil, 381, Centro, Guarujá / SP - CEP 11410-161",
                telefones: ["(13) 3379-1723", "(13) 99743-2446", "(13) 99776-0633"],
            },
            {
                nome: "CEFIG",
                endereco: "Rua Buenos Aires, 40, Vila Maia, Guarujá / SP - CEP 11410-010",
                telefones: ["(13) 3025-1018"],
            },
            {
                nome: "Clinica Comunik",
                endereco: "Avenida Ademar de Barros, 974, Jardim Primavera, Guarujá / SP - CEP 11410-150",
                telefones: ["(13) 3304-3847", "(13) 98862-9525"],
            },
            {
                nome: "Clínica de Fisioterapia Gadi",
                endereco: "Avenida Guarujá, 247, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11461-000",
                telefones: ["(13) 3018-3188", "(13) 3379-2564"],
            },
            {
                nome: "Healt Fisioterapia e Bem Estar",
                endereco: "Rua Professor Waldery de Almeida, 594, Jardim Progresso (Vicente de Carvalho), Guarujá / SP - CEP 11453-020",
                telefones: ["(13) 99608-3907"],
            },
            {
                nome: "Unita Class Saúde",
                endereco: "Avenida Santos Dumont, 264, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 3341-4114", "(13) 99746-1075"],
            },
        ],
    },
    {
        especialidade: "Fisioterapia - Infantil",
        estabelecimentos: [
            {
                nome: "Clínica de Fisioterapia Gadi",
                endereco: "Avenida Guarujá, 247, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11461-000",
                telefones: ["(13) 3018-3188", "(13) 3379-2564"],
            },
        ],
    },
    {
        especialidade: "Fisioterapia - Neurológica",
        estabelecimentos: [
            {
                nome: "Clínica de Fisioterapia Gadi",
                endereco: "Avenida Guarujá, 247, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11461-000",
                telefones: ["(13) 3018-3188", "(13) 3379-2564"],
            },
        ],
    },
    {
        especialidade: "Fisioterapia - Ortopédica",
        estabelecimentos: [
            {
                nome: "CEFIG",
                endereco: "Rua Buenos Aires, 40, Vila Maia, Guarujá / SP - CEP 11410-010",
                telefones: ["(13) 3025-1018"],
            },
            {
                nome: "Clínica de Fisioterapia Gadi",
                endereco: "Avenida Guarujá, 247, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11461-000",
                telefones: ["(13) 3018-3188", "(13) 3379-2564"],
            },
            {
                nome: "Clínica de Fraturas do Guarujá",
                endereco: "Avenida Deputado Emílio Carlos, 223, Vila Maia, Guarujá / SP - CEP 11410-140",
                telefones: ["(13) 3386-3208", "(13) 3386-3315"],
            },
        ],
    },
    {
        especialidade: "Fonoaudiologia",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Leomil, 381, Centro, Guarujá / SP - CEP 11410-161",
                telefones: ["(13) 3379-1723", "(13) 99743-2446", "(13) 99776-0633"],
            },
        ],
    },
    {
        especialidade: "Gastroenterologia",
        estabelecimentos: [
            {
                nome: "Hospital Guarujá",
                endereco: "Avenida Santos Dumont, 3651, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-002",
                telefones: ["(13) 3308-1720"],
            },
            {
                nome: "PTCB Serviços Médicos",
                endereco: "Rua Oscar Sampaio, 65, Jardim Santense (Vicente de Carvalho), Guarujá / SP - CEP 11450-370",
                telefones: ["(13) 3395-7350", "(13) 99607-9317"],
            }
        ],
    },
    {
        especialidade: "Ginecologia",
        estabelecimentos: [
            {
                nome: "Centro Clínico Frei Galvão",
                endereco: "Avenida Santos Dumont, 694, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Centro Médico Cavalcante - Guarujá",
                endereco: "Rua Montenegro, 18, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "PTCB Serviços Médicos",
                endereco: "Rua Oscar Sampaio, 65, Jardim Santense (Vicente de Carvalho), Guarujá / SP - CEP 11450-370",
                telefones: ["(13) 3395-7350", "(13) 99607-9317"],
            },
            {
                nome: "Unita Class Saúde",
                endereco: "Avenida Santos Dumont, 264, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 3341-4114", "(13) 99746-1075"],
            },
        ],
    },
    {
        especialidade: "Ginecologia e Obstetrícia",
        estabelecimentos: [
            {
                nome: "Med Saúde",
                endereco: "Rua Montenegro, 80, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3379-2019"],
            },
            {
                nome: "Clínica Saúde Ideal",
                endereco: "Rua Rio Grande do Sul, 43, Jardim Cunhambebe (Vicente de Carvalho), Guarujá / SP - CEP 11450-130",
                telefones: ["(13) 3341-4321"],
            },
            {
                nome: "Hospital Guarujá",
                endereco: "Avenida Santos Dumont, 3651, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-002",
                telefones: ["(13) 3308-1720"],
            },
            {
                nome: "Unita Class Saúde",
                endereco: "Avenida Santos Dumont, 264, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 3341-4114", "(13) 99746-1075"],
            },
        ],
    },
    {
        especialidade: "Hematologia e Hemoterapia",
        estabelecimentos: [
            {
                nome: "Hospital Guarujá",
                endereco: "Avenida Santos Dumont, 3651, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-002",
                telefones: ["(13) 3308-1720"],
            },
        ],
    },
    {
        especialidade: "Mamografia",
        estabelecimentos: [
            {
                nome: "CDI - Centro Integrado de Diagnosticos",
                endereco: "Avenida Adhemar de Barros, 210, Vila Santo Antônio, Guarujá / SP - CEP 11430-000",
                telefones: ["(13) 3387-1717", "(13) 99659-2777"],
            },
            {
                nome: "Clínica Radiológica do Guarujá",
                endereco: "Avenida Dep Emílio Carlos, 160, Vila Maia, Guarujá / SP - CEP 11410-140",
                telefones: ["(13) 3387-5733"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Washington, 164, Vila Maia, Guarujá / SP - CEP 11410-150",
                telefones: ["(13) 3202-1990"],
            },
        ],
    },
    {
        especialidade: "Medicina Laboratorial",
        estabelecimentos: [
            {
                nome: "+ Med Saúde",
                endereco: "Rua Montenegro, 80, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3379-2019"],
            },
            {
                nome: "Centro Médico Cavalcante - Guarujá",
                endereco: "Rua Montenegro, 18, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Clinasma Laboratório",
                endereco: "Rua Benjamin Constant, 375, Centro, Guarujá / SP - CEP 11410-270",
                telefones: ["(13) 3355-8239", "(13) 3355-8935"],
            },
            {
                nome: "Itapema Laboratório",
                endereco: "Rua Montenegro, 152, Centro, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 2104-9999"],
            },
            {
                nome: "Itapema Laboratório",
                endereco: "Rua Vinte e Quatro de Agosto, 205, Vila Alice (Vicente de Carvalho), Guarujá / SP - CEP 11450-230",
                telefones: ["(13) 3352-1548", "(13) 3352-3923"],
            },
            {
                nome: "Laboratório Gonzaga",
                endereco: "Rua Santo Amaro, 320, Centro, Guarujá / SP - CEP 11410-070",
                telefones: ["(13) 3395-8533"],
            },
            {
                nome: "Vitlab Litoral",
                endereco: "Avenida Puglisi, 815, Centro, Guarujá / SP - CEP 11410-002",
                telefones: ["(13) 3395-6309"],
            },
        ],
    },
    {
        especialidade: "Musicoterapia",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Leomil, 381, Centro, Guarujá / SP - CEP 11410-161",
                telefones: ["(13) 3379-1723", "(13) 99743-2446", "(13) 99776-0633"],
            },
            {
                nome: "Clinica Comunik",
                endereco: "Avenida Ademar de Barros, 974, Jardim Primavera, Guarujá / SP - CEP 11410-150",
                telefones: ["(13) 3304-3847", "(13) 98862-9525"],
            },
        ],
    },
    {
        especialidade: "Neurocirurgia",
        estabelecimentos: [
            {
                nome: "Hospital Guarujá",
                endereco: "Avenida Santos Dumont, 3651, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-002",
                telefones: ["(13) 3308-1720"],
            },
        ],
    },
    {
        especialidade: "Neurologia",
        estabelecimentos: [
            {
                nome: "Hospital Guarujá",
                endereco: "Avenida Santos Dumont, 3651, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-002",
                telefones: ["(13) 3308-1720"],
            },
        ],
    },
    {
        especialidade: "Neurologia Pediátrica",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Leomil, 381, Centro, Guarujá / SP - CEP 11410-161",
                telefones: ["(13) 3379-1723", "(13) 99743-2446", "(13) 99776-0633"],
            },
        ],
    },
    {
        especialidade: "Neuropsicologia",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Leomil, 381, Centro, Guarujá / SP - CEP 11410-161",
                telefones: ["(13) 3379-1723", "(13) 99743-2446", "(13) 99776-0633"],
            },
            {
                nome: "Clinica Comunik",
                endereco: "Avenida Ademar de Barros, 974, Jardim Primavera, Guarujá / SP - CEP 11410-150",
                telefones: ["(13) 3304-3847", "(13) 98862-9525"],
            },
            {
                nome: "Unita Class Saúde",
                endereco: "Avenida Santos Dumont, 264, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 3341-4114", "(13) 99746-1075"],
            },
        ],
    },
    {
        especialidade: "Nutrição",
        estabelecimentos: [
            {
                nome: "CEFIG",
                endereco: "Rua Buenos Aires, 40, Vila Maia, Guarujá / SP - CEP 11410-010",
                telefones: ["(13) 3025-1018"],
            },
            {
                nome: "Clinica Comunik",
                endereco: "Avenida Ademar de Barros, 974, Jardim Primavera, Guarujá / SP - CEP 11410-150",
                telefones: ["(13) 3304-3847", "(13) 98862-9525"],
            },
            {
                nome: "Hospital Guarujá",
                endereco: "Avenida Santos Dumont, 3651, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-002",
                telefones: ["(13) 3308-1720"],
            },
            {
                nome: "PTCB Serviços Médicos",
                endereco: "Rua Oscar Sampaio, 65, Jardim Santense (Vicente de Carvalho), Guarujá / SP - CEP 11450-370",
                telefones: ["(13) 3395-7350", "(13) 99607-9317"],
            },
            {
                nome: "Unita Class Saúde",
                endereco: "Avenida Santos Dumont, 264, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 3341-4114", "(13) 99746-1075"],
            },
        ],
    },
    {
        especialidade: "Odontopediatria",
        estabelecimentos: [
            {
                nome: "Atelie da Saúde",
                endereco: "Rua Buenos Aires, 611, Vila Maia, Guarujá / SP - CEP 11410-010",
                telefones: ["(13) 99165-4416"],
            },
            {
                nome: "Centro Odontológico Pradent",
                endereco: "Alameda das Margaridas, 777, Jardim Primavera, Guarujá / SP - CEP 11432-240",
                telefones: ["(13) 3383-3094", "(13) 98855-2216"],
            },
            {
                nome: "Mariana Macedo Teixeira",
                endereco: "Avenida Arthur Costa Filho, 24, Vila Maia, Guarujá / SP - CEP 11410-080",
                telefones: ["(13) 99142-0614"],
            },
            {
                nome: "Miguel Angelo Nicotra",
                endereco: "Avenida Adhemar de Barros, 1347, Vila Santa Rosa, Guarujá / SP - CEP 11430-003",
                telefones: ["(13) 3358-1328"],
            },
            {
                nome: "Naline Guimaraes Mazuchi",
                endereco: "Rua Montenegro, 169, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 99644-1314"],
            },
            {
                nome: "Suzy De Paula Correa",
                endereco: "Rua Doutor Guilherme Guinle, 469, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11450-260",
                telefones: ["(13) 3326-5177", "(13) 98109-1507"],
            },
        ],
    },
    {
        especialidade: "Oftalmologia",
        estabelecimentos: [
            {
                nome: "Clínica Saúde Ideal",
                endereco: "Rua Rio Grande do Sul, 43, Jardim Cunhambebe (Vicente de Carvalho), Guarujá / SP - CEP 11450-130",
                telefones: ["(13) 3341-4321"],
            },
            {
                nome: "Clínica de Olhos Dra Patricia Tamayose",
                endereco: "Rua Washington, 140, Centro, Guarujá / SP - CEP 11410-150",
                telefones: ["(13) 3382-3982"],
            },
            {
                nome: "Hospital Guarujá",
                endereco: "Avenida Santos Dumont, 3651, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-002",
                telefones: ["(13) 3308-1720"],
            },
            {
                nome: "PTCB Serviços Médicos",
                endereco: "Rua Oscar Sampaio, 65, Jardim Santense (Vicente de Carvalho), Guarujá / SP - CEP 11450-370",
                telefones: ["(13) 3395-7350", "(13) 99607-9317"],
            },
            {
                nome: "Unita Class Saúde",
                endereco: "Avenida Santos Dumont, 264, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 3341-4114", "(13) 99746-1075"],
            },
        ],
    },
    {
        especialidade: "Ortodontia",
        estabelecimentos: [
            {
                nome: "Atelie da Saúde",
                endereco: "Rua Buenos Aires, 611, Vila Maia, Guarujá / SP - CEP 11410-010",
                telefones: ["(13) 99165-4416"],
            },
            {
                nome: "Centro Odontológico Pradent",
                endereco: "Alameda das Margaridas, 777, Jardim Primavera, Guarujá / SP - CEP 11432-240",
                telefones: ["(13) 3383-3094", "(13) 98855-2216"],
            },
            {
                nome: "Clínica Dra. Bia Padilha",
                endereco: "Avenida Puglisi, 310, Centro, Guarujá / SP - CEP 11410-000",
                telefones: ["(13) 3355-5120", "(13) 99736-5046"],
            },
            {
                nome: "Michele Cristine Batista do Nascimento",
                endereco: "Rua Herman Stadie, 48, Jardim Santa Maria, Guarujá / SP - CEP 11432-210",
                telefones: ["(13) 99198-9542"],
            },
            {
                nome: "Naso",
                endereco: "Rua Quintino Bocaiúva, 461, Centro, Guarujá / SP - CEP 11410-030",
                telefones: ["(13) 3355-8702", "(13) 97421-0838"],
            },
            {
                nome: "Tradeodonto Serviços Odontológicos",
                endereco: "Rua Tunekichi Matsumoto, 45, Jardim Santa Maria, Guarujá / SP - CEP 11432-220",
                telefones: ["(13) 3384-1639", "(13) 99770-1345"],
            },
        ],
    },
    {
        especialidade: "Ortopedia e Traumatologia",
        estabelecimentos: [
            {
                nome: "+ Med Saúde",
                endereco: "Rua Montenegro, 80, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3379-2019"],
            },
            {
                nome: "Centro Médico Cavalcante - Guarujá",
                endereco: "Rua Montenegro, 18, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Centro Médico do Guarujá",
                endereco: "Rua Rio Grande do Sul, 43, Jardim Cunhambebe (Vicente de Carvalho), Guarujá / SP - CEP 11450-130",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Clínica de Fraturas do Guarujá",
                endereco: "Avenida Deputado Emílio Carlos, 223, Vila Maia, Guarujá / SP - CEP 11410-140",
                telefones: ["(13) 3386-3208", "(13) 3386-3315"],
            },
            {
                nome: "Hospital Guarujá",
                endereco: "Avenida Santos Dumont, 3651, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-002",
                telefones: ["(13) 3308-1720"],
            },
            {
                nome: "Unita Class Saúde",
                endereco: "Avenida Santos Dumont, 264, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 3341-4114", "(13) 99746-1075"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Mão e Punho",
        estabelecimentos: [
            {
                nome: "PTCB Serviços Médicos",
                endereco: "Rua Oscar Sampaio, 65, Jardim Santense (Vicente de Carvalho), Guarujá / SP - CEP 11450-370",
                telefones: ["(13) 3395-7350", "(13) 99607-9317"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Mão e Punho",
        estabelecimentos: [
            {
                nome: "Clínica de Fraturas do Guarujá",
                endereco: "Avenida Deputado Emílio Carlos, 223, Vila Maia, Guarujá / SP - CEP 11410-140",
                telefones: ["(13) 3386-3208", "(13) 3386-3315"],
            },
        ],
    },
    {
        especialidade: "Otorrinolaringologia",
        estabelecimentos: [
            {
                nome: "+ Med Saúde",
                endereco: "Rua Montenegro, 80, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3379-2019"],
            },
            {
                nome: "Centro Médico Cavalcante - Guarujá",
                endereco: "Rua Montenegro, 18, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Centro Médico do Guarujá",
                endereco: "Rua Rio Grande do Sul, 43, Jardim Cunhambebe (Vicente de Carvalho), Guarujá / SP - CEP 11450-130",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Hospital Guarujá",
                endereco: "Avenida Santos Dumont, 3651, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-002",
                telefones: ["(13) 3308-1720"],
            },
            {
                nome: "Unita Class Saúde",
                endereco: "Avenida Santos Dumont, 264, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 3341-4114", "(13) 99746-1075"],
            },
        ],
    },
    {
        especialidade: "Pediatria",
        estabelecimentos: [
            {
                nome: "+ Med Saúde",
                endereco: "Rua Montenegro, 80, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3379-2019"],
            },
            {
                nome: "Centro Clínico Frei Galvão",
                endereco: "Avenida Santos Dumont, 694, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Centro Médico Cavalcante - Guarujá",
                endereco: "Rua Montenegro, 18, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Centro Médico do Guarujá",
                endereco: "Rua Rio Grande do Sul, 43, Jardim Cunhambebe (Vicente de Carvalho), Guarujá / SP - CEP 11450-130",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Hospital Guarujá",
                endereco: "Avenida Santos Dumont, 3651, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-002",
                telefones: ["(13) 3308-1720"],
            },
            {
                nome: "M. G. da Silva Neto Clinica - Me",
                endereco: "Rua Rio Grande do Sul, 43, Vicente de Carvalho, Guarujá / SP - CEP 11050-000",
                telefones: ["(13) 99698-0000"],
            },
            {
                nome: "Unita Class Saúde",
                endereco: "Avenida Santos Dumont, 264, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 3341-4114", "(13) 99746-1075"],
            },
        ],
    },
    {
        especialidade: "Periodontia",
        estabelecimentos: [
            {
                nome: "Paula Bocarde Nagaishi",
                endereco: "Avenida Antenor Pimentel, 1536, Morrinhos, Guarujá / SP - CEP 11495-000",
                telefones: ["(13) 99718-3281"],
            },
        ],
    },
    {
        especialidade: "Pneumologia",
        estabelecimentos: [
            {
                nome: "Hospital Guarujá",
                endereco: "Avenida Santos Dumont, 3651, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-002",
                telefones: ["(13) 3308-1720"],
            },
        ],
    },
    {
        especialidade: "Prótese Dentária",
        estabelecimentos: [
            {
                nome: "Atelie da Saúde",
                endereco: "Rua Buenos Aires, 611, Vila Maia, Guarujá / SP - CEP 11410-010",
                telefones: ["(13) 99165-4416"],
            },
            {
                nome: "Naso",
                endereco: "Rua Quintino Bocaiúva, 461, Centro, Guarujá / SP - CEP 11410-030",
                telefones: ["(13) 3355-8702", "(13) 97421-0838"],
            },
        ],
    },
    {
        especialidade: "Psicologia",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Leomil, 381, Centro, Guarujá / SP - CEP 11410-161",
                telefones: ["(13) 3379-1723", "(13) 99743-2446", "(13) 99776-0633"],
            },
            {
                nome: "CEFIG",
                endereco: "Rua Buenos Aires, 40, Vila Maia, Guarujá / SP - CEP 11410-010",
                telefones: ["(13) 3025-1018"],
            },
            {
                nome: "Centro Médico do Guarujá",
                endereco: "Rua Rio Grande do Sul, 43, Jardim Cunhambebe (Vicente de Carvalho), Guarujá / SP - CEP 11450-130",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Clinasma Laboratório",
                endereco: "Rua Benjamin Constant, 375, Centro, Guarujá / SP - CEP 11410-270",
                telefones: ["(13) 3355-8239", "(13) 3355-8935"],
            },
            {
                nome: "Clinica Comunik",
                endereco: "Avenida Ademar de Barros, 974, Jardim Primavera, Guarujá / SP - CEP 11410-150",
                telefones: ["(13) 3304-3847", "(13) 98862-9525"],
            },
            {
                nome: "PTCB Serviços Médicos",
                endereco: "Rua Oscar Sampaio, 65, Jardim Santense (Vicente de Carvalho), Guarujá / SP - CEP 11450-370",
                telefones: ["(13) 3395-7350", "(13) 99607-9317"],
            },
            {
                nome: "Unita Class Saúde",
                endereco: "Avenida Santos Dumont, 264, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 3341-4114", "(13) 99746-1075"],
            },
            {
                nome: "Clinica Comunik",
                endereco: "Avenida Ademar de Barros, 974, Jardim Primavera, Guarujá / SP - CEP 11410-150",
                telefones: ["(13) 3304-3847", "(13) 98862-9525"],
            },
            {
                nome: "Valderez Gonsalez Psicologia",
                endereco: "Avenida Arthur Costa Filho, 159, Vila Maia, Guarujá / SP - CEP 11410-080",
                telefones: ["(13) 99162-1263"],
            },
        ],
    },
    {
        especialidade: "Psicomotricidade",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Leomil, 381, Centro, Guarujá / SP - CEP 11410-161",
                telefones: ["(13) 3379-1723", "(13) 99743-2446", "(13) 99776-0633"],
            }
        ],
    },
    {
        especialidade: "Psicopedagogia",
        estabelecimentos: [
            {
                nome: "PTCB Serviços Médicos",
                endereco: "Rua Oscar Sampaio, 65, Jardim Santense (Vicente de Carvalho), Guarujá / SP - CEP 11450-370",
                telefones: ["(13) 3395-7350", "(13) 99607-9317"],
            },
            {
                nome: "Unita Class Saúde",
                endereco: "Avenida Santos Dumont, 264, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 3341-4114", "(13) 99746-1075"],
            },
        ],
    },
    {
        especialidade: "Psiquiatria",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Leomil, 381, Centro, Guarujá / SP - CEP 11410-161",
                telefones: ["(13) 3379-1723", "(13) 99743-2446", "(13) 99776-0633"],
            },
            {
                nome: "Centro Médico Cavalcante - Guarujá",
                endereco: "Rua Montenegro, 18, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Centro Médico do Guarujá",
                endereco: "Rua Rio Grande do Sul, 43, Jardim Cunhambebe (Vicente de Carvalho), Guarujá / SP - CEP 11450-130",
                telefones: ["0800 521-0101"],
            },
        ],
    },
    {
        especialidade: "Psiquiatria da Infância e da Adolescência",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Leomil, 381, Centro, Guarujá / SP - CEP 11410-161",
                telefones: ["(13) 3379-1723", "(13) 99743-2446", "(13) 99776-0633"],
            },
        ],
    },
    {
        especialidade: "Radiologia e Diagnóstico por Imagem",
        estabelecimentos: [
            {
                nome: "Clínica Radiológica do Guarujá",
                endereco: "Avenida Dep Emílio Carlos, 160, Vila Maia, Guarujá / SP - CEP 11410-140",
                telefones: ["(13) 3387-5733"],
            },
        ],
    },
    {
        especialidade: "Ressonância Magnética",
        estabelecimentos: [
            {
                nome: "Clínica Radiológica do Guarujá",
                endereco: "Avenida Dep Emílio Carlos, 160, Vila Maia, Guarujá / SP - CEP 11410-140",
                telefones: ["(13) 3387-5733"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Washington, 164, Vila Maia, Guarujá / SP - CEP 11410-150",
                telefones: ["(13) 3202-1990"],
            },
        ],
    },
    {
        especialidade: "Reumatologia",
        estabelecimentos: [
            {
                nome: "Centro Médico do Guarujá",
                endereco: "Rua Rio Grande do Sul, 43, Jardim Cunhambebe (Vicente de Carvalho), Guarujá / SP - CEP 11450-130",
                telefones: ["0800 521-0101"],
            },
        ],
    },
    {
        especialidade: "RX",
        estabelecimentos: [
            {
                nome: "CDI - Centro Integrado de Diagnosticos",
                endereco: "Avenida Adhemar de Barros, 210, Vila Santo Antônio, Guarujá / SP - CEP 11430-000",
                telefones: ["(13) 3387-1717", "(13) 99659-2777"],
            },
            {
                nome: "Clínica Radiológica do Guarujá",
                endereco: "Avenida Dep Emílio Carlos, 160, Vila Maia, Guarujá / SP - CEP 11410-140",
                telefones: ["(13) 3387-5733"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Washington, 164, Vila Maia, Guarujá / SP - CEP 11410-150",
                telefones: ["(13) 3202-1990"],
            },
        ],
    },
    {
        especialidade: "Terapia Ocupacional",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Leomil, 381, Centro, Guarujá / SP - CEP 11410-161",
                telefones: ["(13) 3379-1723", "(13) 99743-2446", "(13) 99776-0633"],
            },
            {
                nome: "Clinica Comunik",
                endereco: "Avenida Ademar de Barros, 974, Jardim Primavera, Guarujá / SP - CEP 11410-150",
                telefones: ["(13) 3304-3847", "(13) 98862-9525"],
            },
        ],
    },
    {
        especialidade: "Tomografia Computadorizada",
        estabelecimentos: [
            {
                nome: "Clínica Radiológica do Guarujá",
                endereco: "Avenida Dep Emílio Carlos, 160, Vila Maia, Guarujá / SP - CEP 11410-140",
                telefones: ["(13) 3387-5733"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Washington, 164, Vila Maia, Guarujá / SP - CEP 11410-150",
                telefones: ["(13) 3202-1990"],
            },
        ],
    },
    {
        especialidade: "Ultrassonografia Geral",
        estabelecimentos: [
            {
                nome: "+ Med Saúde",
                endereco: "Rua Montenegro, 80, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3379-2019"],
            },
            {
                nome: "CDI - Centro Integrado de Diagnosticos",
                endereco: "Avenida Adhemar de Barros, 210, Vila Santo Antônio, Guarujá / SP - CEP 11430-000",
                telefones: ["(13) 3387-1717", "(13) 99659-2777"],
            },
            {
                nome: "Centro Médico Cavalcante - Guarujá",
                endereco: "Rua Montenegro, 18, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Clínica Radiológica do Guarujá",
                endereco: "Avenida Dep Emílio Carlos, 160, Vila Maia, Guarujá / SP - CEP 11410-140",
                telefones: ["(13) 3387-5733"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Washington, 164, Vila Maia, Guarujá / SP - CEP 11410-150",
                telefones: ["(13) 3202-1990"],
            },
            {
                nome: "Unita Class Saúde",
                endereco: "Avenida Santos Dumont, 264, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 3341-4114", "(13) 99746-1075"],
            },
        ],
    },
    {
        especialidade: "Urologia",
        estabelecimentos: [
            {
                nome: "+ Med Saúde",
                endereco: "Rua Montenegro, 80, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3379-2019"],
            },
            {
                nome: "Centro Médico Cavalcante - Guarujá",
                endereco: "Rua Montenegro, 18, Vila Maia, Guarujá / SP - CEP 11410-040",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Centro Médico do Guarujá",
                endereco: "Rua Rio Grande do Sul, 43, Jardim Cunhambebe (Vicente de Carvalho), Guarujá / SP - CEP 11450-130",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Hospital Guarujá",
                endereco: "Avenida Santos Dumont, 3651, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-002",
                telefones: ["(13) 3308-1720"],
            },
            {
                nome: "PTCB Serviços Médicos",
                endereco: "Rua Oscar Sampaio, 65, Jardim Santense (Vicente de Carvalho), Guarujá / SP - CEP 11450-370",
                telefones: ["(13) 3395-7350", "(13) 99607-9317"],
            },
            {
                nome: "Unita Class Saúde",
                endereco: "Avenida Santos Dumont, 264, Sítio Paecara (Vicente de Carvalho), Guarujá / SP - CEP 11460-000",
                telefones: ["(13) 3341-4114", "(13) 99746-1075"],
            },
        ],
    }
];

export default RedesGuaruja;