import './CardPlanos.css';
import btnwpp from '../../../../assets/img-main/btn-plan-wpp.png';

const CardPlanos = () => {
    return (
        <section className="card-planos-section">
            <div className="card-planos-container">

                <div className="card-planos__adesao">

                    <div className="card-planos__text">
                        <h3>
                            Plano de Saúde por <strong>Adesão:<br /></strong>
                        </h3>
                        <p>
                            <strong>Destinado a profissionais de categorias específicas ou associados a entidades profissionais.</strong> Este tipo de plano oferece condições especiais de contratação devido à negociação coletiva, proporcionando acesso a uma ampla gama de serviços médicos e hospitalares com descontos significativos.
                        </p>

                        <div class="div-separate">
                            <span class="style-div-separate"></span>
                        </div>
                    </div>

                    <div className="card-planos__cards">
                        <div className="card bg-plans adesao-card">
                            <h2 className="plan-name">GoCare Litoral A</h2>
                            <p className="plan-starting-price">a partir de:</p>
                            <h2 className="price">R$ 145,61</h2>
                            <p className="age-group"><strong>De 00 a 18 anos</strong></p>
                            <p className="plan__room"><strong>Quarto Coletivo</strong><br />
                                (Em caso de Internação)</p>
                            <div className="hospitals">
                                <p>Hospital Guarujá</p>
                                <p>Hospital Frei Galvão</p>
                                <p>APAS Hospital Santo Expedito</p>
                                <p>Santa Casa de Santos</p>
                                <p>E mais!</p>
                            </div>

                            <div className="quote-button-container">
                                <a
                                    className="quote-button"
                                    href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20GoCare%20Litoral."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Quero uma cotação </p>
                                    <img
                                        src={btnwpp}
                                        alt="Ícone do WhatsApp">
                                    </img>
                                </a>
                            </div>
                        </div>

                        <div className="card bg-plans adesao-card">
                            <h2 className="plan-name">GoCare Litoral<br />
                                Especial A</h2>
                            <p className="plan-starting-price">a partir de:</p>
                            <h2 className="price">R$ 187,77</h2>
                            <p className="age-group"><strong>De 00 a 18 anos</strong></p>
                            <p className="plan__room"><strong>Quarto Privativo</strong><br />
                                (Em caso de Internação)</p>
                            <div className="hospitals">
                                <p>Hospital Guarujá</p>
                                <p>Hospital Frei Galvão</p>
                                <p>APAS Hospital Santo Expedito</p>
                                <p>Santa Casa de Santos</p>
                                <p>E mais!</p>
                            </div>

                            <div className="quote-button-container">
                                <a
                                    className="quote-button"
                                    href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20GoCare%20Litoral."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Quero uma cotação </p>
                                    <img
                                        src={btnwpp}
                                        alt="Ícone do WhatsApp">
                                    </img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card-planos__empresa">
                    <div className="card-planos__text card-planos__empresa__text">
                        <h3>
                            Plano de Saúde <strong>Empresarial:<br /></strong>
                        </h3>
                        <p>
                            <strong>Oferecido por empresas aos seus colaboradores como um benefício corporativo.</strong> Além de promover o cuidado com a saúde dos funcionários, os planos empresariais ajudam a reduzir o absenteísmo, melhoram a produtividade e contribuem para a atração e retenção de talentos. Podem incluir coberturas variadas e condições vantajosas devido ao número de participantes.
                        </p>
                        <div class="div-separate">
                            <span class="style-div-separate"></span>
                        </div>
                    </div>

                    <div className="card-planos__cards">
                        <div className="card bg-plans empresarial-card">
                            <h2 className="plan-name">GoCare Litoral E</h2>
                            <p className="plan-starting-price">a partir de:</p>
                            <h2 className="price">R$ 129,37</h2>
                            <p className="age-group"><strong>De 00 a 18 anos</strong></p>
                            <p className="plan__room"><strong>Quarto Coletivo</strong><br />
                                (Em caso de Internação)</p>
                            <div className="hospitals">
                                <p>Hospital Guarujá</p>
                                <p>Hospital Frei Galvão</p>
                                <p>APAS Hospital Santo Expedito</p>
                                <p>Santa Casa de Santos</p>
                                <p>E mais!</p>
                            </div>
                            <div className="quote-button-container">
                                <a
                                    className="quote-button"
                                    href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20GoCare%20Litoral."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Quero uma cotação </p>
                                    <img
                                        src={btnwpp}
                                        alt="Ícone do WhatsApp">
                                    </img>
                                </a>
                            </div>
                        </div>

                        <div className="card bg-plans empresarial-card">
                            <h2 className="plan-name">GoCare Litoral<br />
                                Especial E</h2>
                            <p className="plan-starting-price">a partir de:</p>
                            <h2 className="price">R$ 148,78</h2>
                            <p className="age-group"><strong>De 00 a 18 anos</strong></p>
                            <p className="plan__room"><strong>Quarto Privativo</strong><br />
                                (Em caso de Internação)</p>

                            <div className="hospitals">
                                <p>Hospital Guarujá</p>
                                <p>Hospital Frei Galvão</p>
                                <p>APAS Hospital Santo Expedito</p>
                                <p>Santa Casa de Santos</p>
                                <p>E mais!</p>
                            </div>

                            <div className="quote-button-container">
                                <a
                                    className="quote-button"
                                    href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20GoCare%20Litoral."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Quero uma cotação </p>
                                    <img
                                        src={btnwpp}
                                        alt="Ícone do WhatsApp">
                                    </img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card-planos__indfam">
                    <div className="card-planos__text card-planos__indfam__text">
                        <h3>
                            Plano de Saúde <strong>Individual e Familiar:<br /></strong>
                        </h3>
                        <p>
                            O <strong>plano individual oferece flexibilidade e personalização no atendimento médico</strong>, permitindo a escolha de médicos e serviços conforme sua preferência. Já o <strong>plano familiar garante proteção abrangente para toda a família em um único contrato</strong>, com tarifas vantajosas e acesso facilitado a uma ampla rede de serviços de saúde.
                        </p>
                        <div class="div-separate">
                            <span class="style-div-separate"></span>
                        </div>
                    </div>

                    <div className="card-planos__cards">
                        <div className="card bg-plans indfam-card">
                            <h2 className="plan-name">GoCare Litoral I</h2>
                            <p className="plan-starting-price">a partir de:</p>
                            <h2 className="price">R$ 207,00</h2>
                            <p className="age-group"><strong>De 00 a 18 anos</strong></p>
                            <p className="plan__room"><strong>Quarto Coletivo</strong><br />
                                (Em caso de Internação)</p>
                            <div className="hospitals">
                                <p>Hospital Guarujá</p>
                                <p>Hospital Frei Galvão</p>
                                <p>APAS Hospital Santo Expedito</p>
                                <p>Santa Casa de Santos</p>
                                <p>E mais!</p>
                            </div>

                            <div className="quote-button-container">
                                <a
                                    className="quote-button"
                                    href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20GoCare%20Litoral."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Quero uma cotação </p>
                                    <img
                                        src={btnwpp}
                                        alt="Ícone do WhatsApp">
                                    </img>
                                </a>
                            </div>
                        </div>

                        <div className="card bg-plans indfam-card">
                            <h2 className="plan-name">GoCare Litoral<br />
                                Especial I</h2>
                            <p className="plan-starting-price">a partir de:</p>
                            <h2 className="price">R$ 238,05</h2>
                            <p className="age-group"><strong>De 00 a 18 anos</strong></p>
                            <p className="plan__room"><strong>Quarto Privativo</strong><br />
                                (Em caso de Internação)</p>
                            <div className="hospitals">
                                <p>Hospital Guarujá</p>
                                <p>Hospital Frei Galvão</p>
                                <p>APAS Hospital Santo Expedito</p>
                                <p>Santa Casa de Santos</p>
                                <p>E mais!</p>
                            </div>

                            <div className="quote-button-container">
                                <a
                                    className="quote-button"
                                    href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20GoCare%20Litoral."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Quero uma cotação </p>
                                    <img
                                        src={btnwpp}
                                        alt="Ícone do WhatsApp">
                                    </img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card-planos__adesao">

                    <div className="card-planos__text">
                        <h3>
                            Plano de Saúde por <strong>Adesão Estudantil:<br /></strong>
                        </h3>
                        <p>
                            <strong>Exclusivo para estudantes e profissionais liberais.</strong> Este plano oferece condições exclusivas, negociadas coletivamente para garantir acesso a uma ampla gama de serviços médicos e hospitalares com descontos significativos, proporcionando segurança e bem-estar durante sua jornada.
                        </p>

                        <div class="div-separate">
                            <span class="style-div-separate"></span>
                        </div>
                    </div>

                    <div className="card-planos__cards">
                        <div className="card bg-plans adesao-card">
                            <h2 className="plan-name">GoCare Litoral A</h2>
                            <p className="plan-starting-price">a partir de:</p>
                            <h2 className="price">R$ 141,74</h2>
                            <p className="age-group"><strong>De 00 a 18 anos</strong></p>
                            <p className="plan__room"><strong>Quarto Coletivo</strong><br />
                                (Em caso de Internação)</p>
                            <div className="hospitals">
                                <p>Hospital Guarujá</p>
                                <p>Hospital Frei Galvão</p>
                                <p>APAS Hospital Santo Expedito</p>
                                <p>Santa Casa de Santos</p>
                                <p>E mais!</p>
                            </div>

                            <div className="quote-button-container">
                                <a
                                    className="quote-button"
                                    href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20GoCare%20Litoral."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Quero uma cotação </p>
                                    <img
                                        src={btnwpp}
                                        alt="Ícone do WhatsApp">
                                    </img>
                                </a>
                            </div>
                        </div>

                        <div className="card bg-plans adesao-card">
                            <h2 className="plan-name">GoCare Litoral<br />
                                Especial A</h2>
                            <p className="plan-starting-price">a partir de:</p>
                            <h2 className="price">R$ 182,78</h2>
                            <p className="age-group"><strong>De 00 a 18 anos</strong></p>
                            <p className="plan__room"><strong>Quarto Privativo</strong><br />
                                (Em caso de Internação)</p>
                            <div className="hospitals">
                                <p>Hospital Guarujá</p>
                                <p>Hospital Frei Galvão</p>
                                <p>APAS Hospital Santo Expedito</p>
                                <p>Santa Casa de Santos</p>
                                <p>E mais!</p>
                            </div>

                            <div className="quote-button-container">
                                <a
                                    className="quote-button"
                                    href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20GoCare%20Litoral."
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <p className="quote-button-text">
                                        Quero uma cotação </p>
                                    <img
                                        src={btnwpp}
                                        alt="Ícone do WhatsApp">
                                    </img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </section>
    );
}

export default CardPlanos;
