import React from 'react';
import { Helmet } from 'react-helmet';

import imgtelemed from '../../../../assets/img-main/infopage/Telemedicina.png';
import imgtelemed_mobile from '../../../../assets/img-main/infopage/Telemedicina-mobile.png';
import './Telemedicina.css';

import wppinfopage from '../../../../assets/img-main/wpplogo.png';
import telinfopage from '../../../../assets/img-main/tel-infopage.png'

const Telemedicina = () => (
    <>
        <Helmet>
            <title>Telemedicina 24 Horas | GoCare Litoral</title>
            <meta name="description" content="Faça o agendamento de suas consultas médicas online com nossos especialistas diretamente dos canais." />
        </Helmet>
        <section className="telemed-page">
            <picture className="telemed-page__img">
                <source media="(min-width: 768px)" srcSet={imgtelemed} />
                <source media="(max-width: 767px)" srcSet={imgtelemed_mobile} />
                <img src={imgtelemed} alt="Telemedicina 24 Horas" style={{ maxWidth: '100%', height: 'auto' }} />
            </picture>

            <h1>Telemedicina no Aplicativo</h1>
            <p>Faça o agendamento de suas consultas médicas online com nossos especialistas diretamente dos canais abaixo:</p>

            <div className='contato__telemed'>
                <div className='contato__telemed-container'>
                    <img
                        src={telinfopage}
                        alt='ícone WhatsApp'
                    />

                    <p>
                        (19) 3116-7262
                    </p>
                    <h4>
                        De Segunda a Sexta-Feira
                    </h4>
                </div>

                <div className='contato__telemed-container'>
                    <img
                        src={wppinfopage}
                        alt='ícone WhatsApp'
                    />
                    <p>
                        (19) 97146-5843
                    </p>

                    <h4>
                        Aos Finais de Semana
                    </h4>
                </div>

                <div className='contato__telemed-container'>
                    <img
                        src={wppinfopage}
                        alt='ícone WhatsApp'
                    />
                    <p>
                        (43) 99163-9939
                    </p>

                    <h4>
                        Clínica Médica e Pediatria
                    </h4>
                </div>
            </div>
        </section>
    </>
);

export default Telemedicina;
