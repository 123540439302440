const RedesPraiaGrande = [
    {
        especialidade: "Acupuntura",
        estabelecimentos: [
            {
                nome: "Clínica Diomed",
                endereco: "Rua São Caetano, 235, Boqueirão, Praia Grande / SP - CEP 11701-230",
                telefones: ["(13) 99114-4881"],
            },
            {
                nome: "Inst de Reabilitação Fisiolife",
                endereco: "Avenida Ubirajara Keutenedjian, 641, Mirim, Praia Grande / SP - CEP 11705-065",
                telefones: ["(13) 3568-4987"],
            },
        ],
    },
    {
        especialidade: "Alergia e Imunologia",
        estabelecimentos: [
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Anatomia Patológica",
        estabelecimentos: [
            {
                nome: "Eurofns Clinical Diagnostics",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 2127-7000", "(13) 3324-2196"],
            },
            {
                nome: "Laboratório Clínico Santa Clara",
                endereco: "Avenida Marechal Mallet, 1413, Canto do Forte, Praia Grande / SP - CEP 11400-700",
                telefones: ["(13) 3221-8124", "(13) 99183-0475"],
            },
            {
                nome: "Laboratório Gonzaga",
                endereco: "Avenida Presidente Costa e Silva, 609, Boqueirão, Praia Grande / SP - CEP 11701-000",
                telefones: ["(13) 3473-6351"],
            },
            {
                nome: "Laboratório Gonzaga",
                endereco: "Rua Comendador Otto Carlos Golanda, 589, Ocian, Praia Grande / SP - CEP 11704-450",
                telefones: ["(13) 3494-5879"],
            },
            {
                nome: "Laboratório Maria Cristina",
                endereco: "Rua Dair Borges, 567, Boqueirão, Praia Grande / SP - CEP 11701-210",
                telefones: ["(13) 3474-5134", "(13) 99191-2760"],
            },
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Cardiologia",
        estabelecimentos: [
            {
                nome: "Centro Médico Cavalcante - Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Master Medicina Avançada Resolutiva LTDA.",
                endereco: "Rua Mococa, 241, Boqueirão, Praia Grande / SP - CEP 11701-100",
                telefones: ["(13) 3491-3957"],
            },
        ],
    },
    {
        especialidade: "Cirurgia do Aparelho Digestivo",
        estabelecimentos: [
            {
                nome: "Centro Médico Cavalcante - Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3304-5401"],
            },
        ],
    },
    {
        especialidade: "Cirurgia Geral",
        estabelecimentos: [
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Cirurgia Geral Odontológica",
        estabelecimentos: [
            {
                nome: "Camila Cordeiro Nogueira",
                endereco: "Avenida Presidente Kennedy, 6275, Solemar, Praia Grande / SP - CEP 11709-005",
                telefones: ["(13) 3494-1553"],
            },
            {
                nome: "Julio Cesar Carrara",
                endereco: "Rua Botafogo, 358, Guilhermina, Praia Grande / SP - CEP 11701-400",
                telefones: ["(13) 3591-3400"],
            },
            {
                nome: "Marcos Roberto De Bastos",
                endereco: "Rua Indaiá, 35, Guilhermina, Praia Grande / SP - CEP 11701-440",
                telefones: ["(13) 99807-7341"],
            },
            {
                nome: "Odonto Spa",
                endereco: "Avenida Presidente Kennedy, 4500, Aviação, Praia Grande / SP - CEP 11702-480",
                telefones: ["(13) 3841-6860", "(19) 98803-5318"],
            },
            {
                nome: "Paola Rita Rodrigues Casa Santa",
                endereco: "Avenida Presidente Kennedy, 16983, Real, Praia Grande / SP - CEP 11708-005",
                telefones: ["(13) 99111-5284"],
            },
            {
                nome: "Realceodonto",
                endereco: "Avenida Presidente Kennedy, 6576, Ocian, Praia Grande / SP - CEP 11704-100",
                telefones: ["(13) 3472-5264", "(13) 3495-6939", "(13) 3495-7614", "(13) 98178-5776"],
            },
        ],
    },
    {
        especialidade: "Cirurgia Vascular",
        estabelecimentos: [
            {
                nome: "Centro Médico Cavalcante - Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3304-5401"],
            },
        ],
    },
    {
        especialidade: "Citopatologia",
        estabelecimentos: [
            {
                nome: "Eurofns Clinical Diagnostics",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 2127-7000", "(13) 3324-2196"],
            },
            {
                nome: "Laboratório Clínico Santa Clara",
                endereco: "Avenida Marechal Mallet, 1413, Canto do Forte, Praia Grande / SP - CEP 11400-700",
                telefones: ["(13) 3221-8124", "(13) 99183-0475"],
            },
            {
                nome: "Laboratório Gonzaga",
                endereco: "Avenida Presidente Costa e Silva, 609, Boqueirão, Praia Grande / SP - CEP 11701-000",
                telefones: ["(13) 3473-6351"],
            },
            {
                nome: "Laboratório Gonzaga",
                endereco: "Rua Comendador Otto Carlos Golanda, 589, Ocian, Praia Grande / SP - CEP 11704-450",
                telefones: ["(13) 3494-5879"],
            },
            {
                nome: "Laboratório Maria Cristina",
                endereco: "Rua Dair Borges, 567, Boqueirão, Praia Grande / SP - CEP 11701-210",
                telefones: ["(13) 3474-5134", "(13) 99191-2760"],
            },
        ],
    },
    {
        especialidade: "Clínica Geral Odontológica",
        estabelecimentos: [
            {
                nome: "Atende. Urgência Odontológica",
                endereco: "Rua Doutor José Carlos de Oliveira, 385, Boqueirão, Praia Grande / SP - CEP 11701-220",
                telefones: ["(13) 99707-3015"],
            },
            {
                nome: "Camila Cordeiro Nogueira",
                endereco: "Avenida Presidente Kennedy, 6275, Solemar, Praia Grande / SP - CEP 11709-005",
                telefones: ["(13) 3494-1553"],
            },
            {
                nome: "Campideli & Castilho Odontológica",
                endereco: "Avenida Presidente Costa e Silva, 670, Boqueirão, Praia Grande / SP - CEP 11700-007",
                telefones: ["(13) 3356-8352", "(13) 98136-3402"],
            },
            {
                nome: "Caroline Normanda De Lima Silva Gomes",
                endereco: "Rua Indaiá, 33, Guilhermina, Praia Grande / SP - CEP 11701-440",
                telefones: ["(13) 3034-3189", "(13) 99145-2374"],
            },
            {
                nome: "Centro Odontológico M&C",
                endereco: "Rua Itibere da Cunha, 64, Samambaia, Praia Grande / SP - CEP 11713-160",
                telefones: ["(13) 3020-0345", "(13) 99753-3419"],
            },
            {
                nome: "Dhaniella Helena Simoes Dias",
                endereco: "Avenida Presidente Costa e Silva, 609, Boqueirão, Praia Grande / SP - CEP 11701-000",
                telefones: ["(13) 3029-5737", "(13) 99749-0911"],
            },
            {
                nome: "Dr. Rafael Regis Odontologia e Estética",
                endereco: "Rua Luiz Antônio de Andrade Vieira, 216, Boqueirão, Praia Grande / SP - CEP 11701-040",
                telefones: ["(13) 99119-7264"],
            },
            {
                nome: "Fabiola Liberato Sgarbi Paes",
                endereco: "Avenida Guadalajara, 30527, Tupi, Praia Grande / SP - CEP 11703-120",
                telefones: ["(13) 3491-1233", "(13) 99113-0863"],
            },
            {
                nome: "Fabiola Liberato Sgarbi Paes",
                endereco: "Rua Sebastião de Oliveira, 97, Boqueirão, Praia Grande / SP - CEP 11701-200",
                telefones: ["(13) 3491-1233", "(13) 99113-0863"],
            },
            {
                nome: "Fabiola Mara Orsi Guimaraes",
                endereco: "Rua Campinas, 78, Boqueirão, Praia Grande / SP - CEP 11701-110",
                telefones: ["(13) 3592-1050"],
            },
            {
                nome: "Icaro Balbino De Castro Junior",
                endereco: "Rua Tiradentes, 615, Canto do Forte, Praia Grande / SP - CEP 11700-290",
                telefones: ["(13) 99642-2740"],
            },
            {
                nome: "Julio Cesar Carrara",
                endereco: "Rua Botafogo, 358, Guilhermina, Praia Grande / SP - CEP 11701-400",
                telefones: ["(13) 3591-3400"],
            },
            {
                nome: "Marcos Roberto De Bastos",
                endereco: "Rua Indaiá, 35, Guilhermina, Praia Grande / SP - CEP 11701-440",
                telefones: ["(13) 99807-7341"],
            },
            {
                nome: "Odonto Spa",
                endereco: "Avenida Presidente Kennedy, 4500, Aviação, Praia Grande / SP - CEP 11702-480",
                telefones: ["(13) 3841-6860", "(19) 98803-5318"],
            },
            {
                nome: "Odontomontera",
                endereco: "Rua Mococa, 241, Boqueirão, Praia Grande / SP - CEP 11701-100",
                telefones: ["(13) 3395-0300", "(13) 99658-7838"],
            },
            {
                nome: "Odontomontera",
                endereco: "Rua Jaú, 1092, Canto do Forte, Praia Grande / SP - CEP 11700-270",
                telefones: ["(13) 3878-2558", "(13) 99634-7430"],
            },
            {
                nome: "Paola Rita Rodrigues Casa Santa",
                endereco: "Avenida Presidente Kennedy, 16983, Real, Praia Grande / SP - CEP 11708-005",
                telefones: ["(13) 99111-5284"],
            },
            {
                nome: "Pro Sorriso Centro Odontológico",
                endereco: "Avenida Doutor Vicente de Carvalho, 550, Ocian, Praia Grande / SP - CEP 11704-300",
                telefones: ["(13) 99667-3996"],
            },
            {
                nome: "Realceodonto",
                endereco: "Avenida Presidente Kennedy, 6576, Ocian, Praia Grande / SP - CEP 11704-100",
                telefones: ["(13) 3472-5264", "(13) 3495-6939", "(13) 3495-7614", "(13) 98178-5776"],
            },
            {
                nome: "Uniero - Mello Radiologia Odontológica - P.G",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3474-5327"],
            },
        ],
    },
    {
        especialidade: "Clínica Médica",
        estabelecimentos: [
            {
                nome: "Centro Médico Cavalcante - Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Coluna",
        estabelecimentos: [
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Densitometria Óssea",
        estabelecimentos: [
            {
                nome: "Cedial Centro de Diagnósticos",
                endereco: "Rua Sebastião de Oliveira, 47, Boqueirão, Praia Grande / SP - CEP 11701-200",
                telefones: ["(13) 3476-6161", "(13) 97409-7900"],
            },
            {
                nome: "Cedipo Centro Diagnóstico Popular ltda.",
                endereco: "Rua Jundiaí, 266, Boqueirão, Praia Grande / SP - CEP 11701-250",
                telefones: ["(13) 3481-3696"],
            },
            {
                nome: "Domínio Imagem PG Clínica Médica",
                endereco: "Rua Luiz Antônio de Andrade Vieira, 216, Boqueirão, Praia Grande / SP - CEP 11701-040",
                telefones: ["(13) 3471-4143"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Turiassu, 214, Guilhermina, Praia Grande / SP - CEP 11701-460",
                telefones: ["(13) 3202-1990"],
            },
        ],
    },
    {
        especialidade: "Dentística",
        estabelecimentos: [
            {
                nome: "Atende. Urgência Odontológica",
                endereco: "Rua Doutor José Carlos de Oliveira, 385, Boqueirão, Praia Grande / SP - CEP 11701-220",
                telefones: ["(13) 99707-3015"],
            },
            {
                nome: "Camila Cordeiro Nogueira",
                endereco: "Avenida Presidente Kennedy, 6275, Solemar, Praia Grande / SP - CEP 11709-005",
                telefones: ["(13) 3494-1553"],
            },
            {
                nome: "Campideli & Castilho Odontológica",
                endereco: "Avenida Presidente Costa e Silva, 670, Boqueirão, Praia Grande / SP - CEP 11700-007",
                telefones: ["(13) 3356-8352", "(13) 98136-3402"],
            },
            {
                nome: "Caroline Normanda De Lima Silva Gomes",
                endereco: "Rua Indaiá, 33, Guilhermina, Praia Grande / SP - CEP 11701-440",
                telefones: ["(13) 3034-3189", "(13) 99145-2374"],
            },
            {
                nome: "Centro Odontológico M&C",
                endereco: "Rua Itibere da Cunha, 64, Samambaia, Praia Grande / SP - CEP 11713-160",
                telefones: ["(13) 3020-0345", "(13) 99753-3419"],
            },
            {
                nome: "Croma Centro de Radiologia Odontológica - Afonso Pena",
                endereco: "Rua Fumio Miyazi, 141, Boqueirão, Praia Grande / SP - CEP 11701-160",
                telefones: ["(13) 3223-3490", "(13) 9973-7054"],
            },
            {
                nome: "Dhaniella Helena Simoes Dias",
                endereco: "Avenida Presidente Costa e Silva, 609, Boqueirão, Praia Grande / SP - CEP 11701-000",
                telefones: ["(13) 3029-5737", "(13) 99749-0911"],
            },
            {
                nome: "Dr. Rafael Regis Odontologia e Estética",
                endereco: "Rua Luiz Antônio de Andrade Vieira, 216, Boqueirão, Praia Grande / SP - CEP 11701-040",
                telefones: ["(13) 99119-7264"],
            },
            {
                nome: "Fabiola Mara Orsi Guimaraes",
                endereco: "Rua Campinas, 78, Boqueirão, Praia Grande / SP - CEP 11701-110",
                telefones: ["(13) 3592-1050"],
            },
            {
                nome: "Julio Cesar Carrara",
                endereco: "Rua Botafogo, 358, Guilhermina, Praia Grande / SP - CEP 11701-400",
                telefones: ["(13) 3591-3400"],
            },
            {
                nome: "Marcos Roberto De Bastos",
                endereco: "Rua Indaiá, 35, Guilhermina, Praia Grande / SP - CEP 11701-440",
                telefones: ["(13) 99807-7341"],
            },
            {
                nome: "Odonto Spa",
                endereco: "Avenida Presidente Kennedy, 4500, Aviação, Praia Grande / SP - CEP 11702-480",
                telefones: ["(13) 3841-6860", "(19) 98803-5318"],
            },
            {
                nome: "Odontomontera",
                endereco: "Rua Mococa, 241, Boqueirão, Praia Grande / SP - CEP 11701-100",
                telefones: ["(13) 3395-0300", "(13) 99658-7838"],
            },
            {
                nome: "Odontomontera",
                endereco: "Rua Jaú, 1092, Canto do Forte, Praia Grande / SP - CEP 11700-270",
                telefones: ["(13) 3878-2558", "(13) 99634-7430"],
            },
            {
                nome: "Paola Rita Rodrigues Casa Santa",
                endereco: "Avenida Presidente Kennedy, 16983, Real, Praia Grande / SP - CEP 11708-005",
                telefones: ["(13) 99111-5284"],
            },
            {
                nome: "Pro Sorriso Centro Odontológico",
                endereco: "Avenida Doutor Vicente de Carvalho, 550, Ocian, Praia Grande / SP - CEP 11704-300",
                telefones: ["(13) 99667-3996"],
            },
            {
                nome: "Realceodonto",
                endereco: "Avenida Presidente Kennedy, 6576, Ocian, Praia Grande / SP - CEP 11704-100",
                telefones: ["(13) 3472-5264", "(13) 3495-6939", "(13) 3495-7614", "(13) 98178-5776"],
            },
            {
                nome: "Up Prime Odontologia",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3591-6262", "(13) 99164-1400"],
            },
        ],
    },
    {
        especialidade: "Dermatologia",
        estabelecimentos: [
            {
                nome: "Centro Médico Cavalcante - Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Ecocardiografia",
        estabelecimentos: [
            {
                nome: "Cedial Centro de Diagnósticos",
                endereco: "Rua Sebastião de Oliveira, 47, Boqueirão, Praia Grande / SP - CEP 11701-200",
                telefones: ["(13) 3476-6161", "(13) 97409-7900"],
            },
        ],
    },
    {
        especialidade: "Ecografia Vascular com Doppler",
        estabelecimentos: [
            {
                nome: "Mega Imagem",
                endereco: "Rua Turiassu, 214, Guilhermina, Praia Grande / SP - CEP 11701-460",
                telefones: ["(13) 3202-1990"],
            },
        ],
    },
    {
        especialidade: "Endocrinologia",
        estabelecimentos: [
            {
                nome: "CEPG - Clínica de Especialidades Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3491-5909", "(13) 99644-6404"],
            },
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Endodontia",
        estabelecimentos: [
            {
                nome: "Centro Odontológico M&C",
                endereco: "Rua Itibere da Cunha, 64, Samambaia, Praia Grande / SP - CEP 11713-160",
                telefones: ["(13) 3020-0345", "(13) 99753-3419"],
            },
            {
                nome: "Dhaniella Helena Simoes Dias",
                endereco: "Avenida Presidente Costa e Silva, 609, Boqueirão, Praia Grande / SP - CEP 11701-000",
                telefones: ["(13) 3029-5737", "(13) 99749-0911"],
            },
            {
                nome: "Icaro Balbino De Castro Junior",
                endereco: "Rua Tiradentes, 615 Canto do Forte, Praia Grande / SP - CEP 11700-290",
                telefones: ["(13) 99642-2740"],
            },
            {
                nome: "Marcos Roberto De Bastos",
                endereco: "Rua Indaiá, 35, Guilhermina, Praia Grande / SP - CEP 11701-440",
                telefones: ["(13) 99807-7341"],
            },
            {
                nome: "Pro Sorriso Centro Odontológico",
                endereco: "Avenida Doutor Vicente de Carvalho, 550, Ocian, Praia Grande / SP - CEP 11704-300",
                telefones: ["(13) 99667-3996"],
            },
            {
                nome: "Up Prime Odontologia",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3591-6262", "(13) 99164-1400"],
            },
        ],
    },
    {
        especialidade: "Endoscopia Digestiva",
        estabelecimentos: [
            {
                nome: "Cedial Centro de Diagnósticos",
                endereco: "Rua Sebastião de Oliveira, 47, Boqueirão, Praia Grande / SP - CEP 11701-200",
                telefones: ["(13) 3476-6161", "(13) 97409-7900"],
            },
        ],
    },
    {
        especialidade: "Fisioterapia",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Doutor Roberto de Almeida Vinhas, 869, Boqueirão, Praia Grande / SP - CEP 11701-350",
                telefones: ["(13) 3591-3618", "(13) 99673-3384", "(13) 99792-5006"],
            },
            {
                nome: "Inst de Reabilitação Fisiolife",
                endereco: "Avenida Ubirajara Keutenedjian, 641, Mirim, Praia Grande / SP - CEP 11705-065",
                telefones: ["(13) 3568-4987"],
            },
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
            {
                nome: "Studio Voll Pilates e Fisioterapia",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3034-1807"],
            },
            {
                nome: "Yfer Saúde Espaço Terapêutico",
                endereco: "Avenida São Paulo, 1058, Boqueirão, Praia Grande / SP - CEP 11701-380 ",
                telefones: ["(13) 98805-7244"],
            },
        ],
    },
    {
        especialidade: "Fisioterapia - Ortopédica",
        estabelecimentos: [
            {
                nome: "Clínica Diomed",
                endereco: "Rua São Caetano, 235, Boqueirão, Praia Grande / SP - CEP 11701-230",
                telefones: ["(13) 99114-4881"],
            },
        ],
    },
    {
        especialidade: "Fonoaudiologia",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Doutor Roberto de Almeida Vinhas, 869, Boqueirão, Praia Grande / SP - CEP 11701-350",
                telefones: ["(13) 3591-3618", "(13) 99673-3384", "(13) 99792-5006"],
            },
            {
                nome: "Centro de Reabilitação Neurológica Matheus Alvares - PG",
                endereco: "Rua Botafogo, 419, Guilhermina, Praia Grande / SP - CEP 11701-400",
                telefones: ["(13) 3591-3157", "(13) 98870-0508"],
            },
        ],
    },
    {
        especialidade: "Gastroenterologia",
        estabelecimentos: [
            {
                nome: "Centro Médico Cavalcante - Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Master Medicina Avançada Resolutiva LTDA.",
                endereco: "Rua Mococa, 241, Boqueirão, Praia Grande / SP - CEP 11701-100",
                telefones: ["(13) 3491-3957"],
            },
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Geriatria",
        estabelecimentos: [
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Ginecologia",
        estabelecimentos: [
            {
                nome: "Centro Médico Cavalcante - Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Laboratório Maria Cristina",
                endereco: "Rua Dair Borges, 567, Boqueirão, Praia Grande / SP - CEP 11701-210",
                telefones: ["(13) 3474-5134", "(13) 99191-2760"],
            },
            {
                nome: "Master Medicina Avançada Resolutiva LTDA.",
                endereco: "Rua Mococa, 241, Boqueirão, Praia Grande / SP - CEP 11701-100",
                telefones: ["(13) 3491-3957"],
            },
        ],
    },
    {
        especialidade: "Ginecologia e Obstetrícia",
        estabelecimentos: [
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Mamografa",
        estabelecimentos: [
            {
                nome: "Cedial Centro de Diagnósticos",
                endereco: "Rua Sebastião de Oliveira, 47, Boqueirão, Praia Grande / SP - CEP 11701-200",
                telefones: ["(13) 3476-6161", "(13) 97409-7900"],
            },
            {
                nome: "Cedipo Centro Diagnóstico Popular ltda.",
                endereco: "Rua Jundiaí, 266, Boqueirão, Praia Grande / SP - CEP 11701-250",
                telefones: ["(13) 3481-3696"],
            },
            {
                nome: "Domínio Imagem PG Clínica Médica",
                endereco: "Rua Luiz Antônio de Andrade Vieira, 216, Boqueirão, Praia Grande / SP - CEP 11701-040",
                telefones: ["(13) 3471-4143"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Turiassu, 214, Guilhermina, Praia Grande / SP - CEP 11701-460",
                telefones: ["(13) 3202-1990"],
            },
        ],
    },
    {
        especialidade: "Medicina Laboratorial",
        estabelecimentos: [
            {
                nome: "Centro Médico Cavalcante - Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Laboratório Clínico Santa Clara",
                endereco: "Avenida Marechal Mallet, 1413, Canto do Forte, Praia Grande / SP - CEP 11400-700",
                telefones: ["(13) 3221-8124", "(13) 99183-0475"],
            },
            {
                nome: "Laboratório Gonzaga",
                endereco: "Avenida Presidente Costa e Silva, 609, Boqueirão, Praia Grande / SP - CEP 11701-000",
                telefones: ["(13) 3473-6351"],
            },
            {
                nome: "Laboratório Gonzaga",
                endereco: "Rua Comendador Otto Carlos Golanda, 589, Ocian, Praia Grande / SP - CEP 11704-450",
                telefones: ["(13) 3494-5879"],
            },
        ],
    },
    {
        especialidade: "Musicoterapia",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Doutor Roberto de Almeida Vinhas, 869, Boqueirão, Praia Grande / SP - CEP 11701-350",
                telefones: ["(13) 3591-3618", "(13) 99673-3384", "(13) 99792-5006"],
            },
            {
                nome: "Centro de Reabilitação Neurológica Matheus Alvares - PG",
                endereco: "Rua Botafogo, 419, Guilhermina, Praia Grande / SP - CEP 11701-400",
                telefones: ["(13) 3591-3157", "(13) 98870-0508"],
            },
        ],
    },
    {
        especialidade: "Neurocirurgia",
        estabelecimentos: [
            {
                nome: "CEPG - Clínica de Especialidades Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3491-5909", "(13) 99644-6404"],
            },
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Neurologia",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Doutor Roberto de Almeida Vinhas, 869, Boqueirão, Praia Grande / SP - CEP 11701-350",
                telefones: ["(13) 3591-3618", "(13) 99673-3384", "(13) 99792-5006"],
            },
            {
                nome: "CEPG - Clínica de Especialidades Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3491-5909", "(13) 99644-6404"],
            },
        ],
    },
    {
        especialidade: "Neurologia Pediátrica",
        estabelecimentos: [
            {
                nome: "CEPG - Clínica de Especialidades Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3491-5909", "(13) 99644-6404"],
            },
        ],
    },
    {
        especialidade: "Neuropsicologia",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Doutor Roberto de Almeida Vinhas, 869, Boqueirão, Praia Grande / SP - CEP 11701-350",
                telefones: ["(13) 3591-3618", "(13) 99673-3384", "(13) 99792-5006"],
            },
        ],
    },
    {
        especialidade: "Nutrição",
        estabelecimentos: [
            {
                nome: "Yfer Saúde Espaço Terapêutico",
                endereco: "Avenida São Paulo, 1058, Boqueirão, Praia Grande / SP - CEP 11701-380",
                telefones: ["(13) 98805-7244"],
            },
        ],
    },
    {
        especialidade: "Odontopediatria",
        estabelecimentos: [
            {
                nome: "Dr. Rafael Regis Odontologia e Estética",
                endereco: "Rua Luiz Antônio de Andrade Vieira, 216, Boqueirão, Praia Grande / SP - CEP 11701-040",
                telefones: ["(13) 99119-7264"],
            },
            {
                nome: "Paola Rita Rodrigues Casa Santa",
                endereco: "Avenida Presidente Kennedy, 16983, Real, Praia Grande / SP - CEP 11708-005",
                telefones: ["(13) 99111-5284"],
            },
        ],
    },
    {
        especialidade: "Oftalmologia",
        estabelecimentos: [
            {
                nome: "Clínica de Olhos Cid Laser",
                endereco: "Avenida Presidente Costa e Silva, 293, Boqueirão, Praia Grande / SP - CEP 11701-000",
                telefones: ["(13) 3271-5768", "(13) 3473-3034"],
            },
            {
                nome: "Hospital De Olhos Praia Grande",
                endereco: "Avenida Presidente Kennedy, 4396, Aviação, Praia Grande / SP - CEP 11702-480",
                telefones: ["(13) 97811-3804"],
            },
            {
                nome: "Master Medicina Avançada Resolutiva LTDA.",
                endereco: "Rua Mococa, 241, Boqueirão, Praia Grande / SP - CEP 11701-100",
                telefones: ["(13) 3491-3957"],
            },
            {
                nome: "Oftalmologia Vila Rica",
                endereco: "Avenida Presidente Kennedy, 5800, Tupi, Praia Grande / SP - CEP 11703-200",
                telefones: ["(13) 3040-6340", "(13) 99104-8577"],
            },
        ],
    },
    {
        especialidade: "Ortodontia",
        estabelecimentos: [
            {
                nome: "Atende. Urgência Odontológica",
                endereco: "Rua Doutor José Carlos de Oliveira, 385, Boqueirão, Praia Grande / SP - CEP 11701-220",
                telefones: ["(13) 99707-3015"],
            },
            {
                nome: "Caroline Normanda De Lima Silva Gomes",
                endereco: "Rua Indaiá, 33, Guilhermina, Praia Grande / SP - CEP 11701-440",
                telefones: ["(13) 3034-3189", "(13) 99145-2374"],
            },
            {
                nome: "Cintia Navarro Thimoteo",
                endereco: "Avenida Presidente Kennedy, 5385, Tupi, Praia Grande / SP - CEP 11703-205",
                telefones: ["(13) 99673-2760"],
            },
            {
                nome: "Dhaniella Helena Simoes Dias",
                endereco: "Avenida Presidente Costa e Silva, 609, Boqueirão, Praia Grande / SP - CEP 11701-000",
                telefones: ["(13) 3029-5737", "(13) 99749-0911"],
            },
            {
                nome: "Fabiola Mara Orsi Guimaraes",
                endereco: "Rua Campinas, 78, Boqueirão, Praia Grande / SP - CEP 11701-110",
                telefones: ["(13) 3592-1050"],
            },
            {
                nome: "Odontomontera",
                endereco: "Rua Mococa, 241, Boqueirão, Praia Grande / SP - CEP 11701-100",
                telefones: ["(13) 3395-0300", "(13) 99658-7838"],
            },
            {
                nome: "Odontomontera",
                endereco: "Rua Jaú, 1092, Canto do Forte, Praia Grande / SP - CEP 11700-270",
                telefones: ["(13) 3878-2558", "(13) 99634-7430"],
            },
        ],
    },
    {
        especialidade: "Ortopedia e Traumatologia",
        estabelecimentos: [
            {
                nome: "Centro Médico Cavalcante - Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Geral",
        estabelecimentos: [
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Infantil",
        estabelecimentos: [
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Joelho",
        estabelecimentos: [
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Mão e Punho",
        estabelecimentos: [
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Ombro e Cotovelo",
        estabelecimentos: [
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Pé e Tornozelo",
        estabelecimentos: [
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Otorrinolaringologia",
        estabelecimentos: [
            {
                nome: "Centro Médico Cavalcante - Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3304-5401"],
            },
        ],
    },
    {
        especialidade: "Pediatria",
        estabelecimentos: [
            {
                nome: "Centro Médico Cavalcante - Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3304-5401"],
            },
        ],
    },
    {
        especialidade: "Periodontia",
        estabelecimentos: [
            {
                nome: "Atende. Urgência Odontológica",
                endereco: "Rua Doutor José Carlos de Oliveira, 385, Boqueirão, Praia Grande / SP - CEP 11701-220",
                telefones: ["(13) 99707-3015"],
            },
            {
                nome: "Cintia Navarro Thimoteo",
                endereco: "Avenida Presidente Kennedy, 5385, Tupi, Praia Grande / SP - CEP 11703-205",
                telefones: ["(13) 99673-2760"],
            },
            {
                nome: "Dhaniella Helena Simoes Dias",
                endereco: "Avenida Presidente Costa e Silva, 609, Boqueirão, Praia Grande / SP - CEP 11701-000",
                telefones: ["(13) 3029-5737", "(13) 99749-0911"],
            },
            {
                nome: "Dr. Rafael Regis Odontologia e Estética",
                endereco: "Rua Luiz Antônio de Andrade Vieira, 216, Boqueirão, Praia Grande / SP - CEP 11701-040",
                telefones: ["(13) 99119-7264"],
            },
            {
                nome: "Fabiola Liberato Sgarbi Paes",
                endereco: "Avenida Guadalajara, 30527, Tupi, Praia Grande / SP - CEP 11703-120",
                telefones: ["(13) 3491-1233", "(13) 99113-0863"],
            },
            {
                nome: "Fabiola Liberato Sgarbi Paes",
                endereco: "Rua Sebastião de Oliveira, 97, Boqueirão, Praia Grande / SP - CEP 11701-200",
                telefones: ["(13) 3491-1233", "(13) 99113-0863"],
            },
            {
                nome: "Realceodonto",
                endereco: "Avenida Presidente Kennedy, 6576, Ocian, Praia Grande / SP - CEP 11704-100",
                telefones: ["(13) 3472-5264", "(13) 3495-6939", "(13) 3495-7614", "(13) 98178-5776"],
            },
        ],
    },
    {
        especialidade: "Pneumologia",
        estabelecimentos: [
            {
                nome: "CEPG - Clínica de Especialidades Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3491-5909", "(13) 99644-6404"],
            },
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Pneumologia Pediátrica",
        estabelecimentos: [
            {
                nome: "CEPG - Clínica de Especialidades Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3491-5909", "(13) 99644-6404"],
            },
        ],
    },
    {
        especialidade: "Prótese Dentária",
        estabelecimentos: [
            {
                nome: "Dr. Rafael Regis Odontologia e Estética",
                endereco: "Rua Luiz Antônio de Andrade Vieira, 216, Boqueirão, Praia Grande / SP - CEP 11701-040",
                telefones: ["(13) 99119-7264"],
            },
            {
                nome: "Marcos Roberto De Bastos",
                endereco: "Rua Indaiá, 35, Guilhermina, Praia Grande / SP - CEP 11701-440",
                telefones: ["(13) 99807-7341"],
            },
            {
                nome: "Odonto Spa",
                endereco: "Avenida Presidente Kennedy, 4500, Aviação, Praia Grande / SP - CEP 11702-480",
                telefones: ["(13) 3841-6860", "(19) 98803-5318"],
            },
            {
                nome: "Up Prime Odontologia",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3591-6262", "(13) 99164-1400"],
            },
        ],
    },
    {
        especialidade: "Psicologia",
        estabelecimentos: [
            {
                nome: "Centro de Reabilitação Neurológica Matheus Alvares - PG",
                endereco: "Rua Botafogo, 419, Guilhermina, Praia Grande / SP - CEP 11701-400",
                telefones: ["(13) 3591-3157", "(13) 98870-0508"],
            },
            {
                nome: "Master Medicina Avançada Resolutiva LTDA.",
                endereco: "Rua Mococa, 241, Boqueirão, Praia Grande / SP - CEP 11701-100",
                telefones: ["(13) 3491-3957"],
            },
            {
                nome: "Yfer Saúde Espaço Terapêutico",
                endereco: "Avenida São Paulo, 1058, Boqueirão, Praia Grande / SP - CEP 11701-380",
                telefones: ["(13) 98805-7244"],
            },
        ],
    },
    {
        especialidade: "Psicomotricidade",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Doutor Roberto de Almeida Vinhas, 869, Boqueirão, Praia Grande / SP - CEP 11701-350",
                telefones: ["(13) 3591-3618", "(13) 99673-3384", "(13) 99792-5006"],
            },
            {
                nome: "Centro de Reabilitação Neurológica Matheus Alvares - PG",
                endereco: "Rua Botafogo, 419, Guilhermina, Praia Grande / SP - CEP 11701-400",
                telefones: ["(13) 3591-3157", "(13) 98870-0508"],
            },
        ],
    },
    {
        especialidade: "Psicopedagogia",
        estabelecimentos: [
            {
                nome: "Centro de Reabilitação Neurológica Matheus Alvares - PG",
                endereco: "Rua Botafogo, 419, Guilhermina, Praia Grande / SP - CEP 11701-400",
                telefones: ["(13) 3591-3157", "(13) 98870-0508"],
            },
        ],
    },
    {
        especialidade: "Psiquiatria",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Doutor Roberto de Almeida Vinhas, 869, Boqueirão, Praia Grande / SP - CEP 11701-350",
                telefones: ["(13) 3591-3618", "(13) 99673-3384", "(13) 99792-5006"],
            },
            {
                nome: "Centro Médico Cavalcante - Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3304-5401"],
            }
        ],
    },
    {
        especialidade: "Ressonância Magnética",
        estabelecimentos: [
            {
                nome: "Cedial Centro de Diagnósticos",
                endereco: "Rua Sebastião de Oliveira, 47, Boqueirão, Praia Grande / SP - CEP 11701-200",
                telefones: ["(13) 3476-6161", "(13) 97409-7900"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Turiassu, 214, Guilhermina, Praia Grande / SP - CEP 11701-460",
                telefones: ["(13) 3202-1990"],
            },
        ],
    },
    {
        especialidade: "RX",
        estabelecimentos: [
            {
                nome: "Cedial Centro de Diagnósticos",
                endereco: "Rua Sebastião de Oliveira, 47, Boqueirão, Praia Grande / SP - CEP 11701-200",
                telefones: ["(13) 3476-6161", "(13) 97409-7900"],
            },
            {
                nome: "Cedipo Centro Diagnóstico Popular Ltda.",
                endereco: "Rua Jundiaí, 266, Boqueirão, Praia Grande / SP - CEP 11701-250",
                telefones: ["(13) 3481-3696"],
            },
            {
                nome: "Domínio Imagem PG Clínica Médica",
                endereco: "Rua Luiz Antônio de Andrade Vieira, 216, Boqueirão, Praia Grande / SP - CEP 11701-040",
                telefones: ["(13) 3471-4143"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Turiassu, 214, Guilhermina, Praia Grande / SP - CEP 11701-460",
                telefones: ["(13) 3202-1990"],
            },
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Terapia Ocupacional",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Doutor Roberto de Almeida Vinhas, 869, Boqueirão, Praia Grande / SP - CEP 11701-350",
                telefones: ["(13) 3591-3618", "(13) 99673-3384", "(13) 99792-5006"],
            },
            {
                nome: "Centro de Reabilitação Neurológica Matheus Alvares - PG",
                endereco: "Rua Botafogo, 419, Guilhermina, Praia Grande / SP - CEP 11701-400",
                telefones: ["(13) 3591-3157", "(13) 98870-0508"],
            },
        ],
    },
    {
        especialidade: "Tomografia Computadorizada",
        estabelecimentos: [
            {
                nome: "Domínio Imagem PG Clínica Médica",
                endereco: "Rua Luiz Antônio de Andrade Vieira, 216, Boqueirão, Praia Grande / SP - CEP 11701-040",
                telefones: ["(13) 3471-4143"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Turiassu, 214, Guilhermina, Praia Grande / SP - CEP 11701-460",
                telefones: ["(13) 3202-1990"],
            },
        ],
    },
    {
        especialidade: "Ultrassonografia Geral",
        estabelecimentos: [
            {
                nome: "Cedial Centro de Diagnósticos",
                endereco: "Rua Sebastião de Oliveira, 47, Boqueirão, Praia Grande / SP - CEP 11701-200",
                telefones: ["(13) 3476-6161", "(13) 97409-7900"],
            },
            {
                nome: "Centro Médico Cavalcante - Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Domínio Imagem PG Clínica Médica",
                endereco: "Rua Luiz Antônio de Andrade Vieira, 216, Boqueirão, Praia Grande / SP - CEP 11701-040",
                telefones: ["(13) 3471-4143"],
            },
            {
                nome: "Laboratório Maria Cristina",
                endereco: "Rua Dair Borges, 567, Boqueirão, Praia Grande / SP - CEP 11701-210",
                telefones: ["(13) 3474-5134", "(13) 99191-2760"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Turiassu, 214, Guilhermina, Praia Grande / SP - CEP 11701-460",
                telefones: ["(13) 3202-1990"],
            },
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    },
    {
        especialidade: "Urologia",
        estabelecimentos: [
            {
                nome: "Centro Médico Cavalcante - Praia Grande",
                endereco: "Avenida Brasil, 600, Boqueirão, Praia Grande / SP - CEP 11701-090",
                telefones: ["(13) 3304-5401"],
            },
            {
                nome: "Orthoclinica Médica",
                endereco: "Avenida Marechal Mallet, 1765, Canto do Forte, Praia Grande / SP - CEP 11700-400",
                telefones: ["(13) 3476-0020"],
            },
        ],
    }
];


export default RedesPraiaGrande;