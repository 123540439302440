import './Home.css';
import { Helmet } from 'react-helmet';

import Carousel from './carousel/Carousel.js';
import BenefitsCarousel from './benefits/Benefits.js';
import usePageTracking from '../../../hooks/usePageTracking.js';

const Home = () => {

    usePageTracking();

    return (
        <div className='home'>
            
            <Helmet>
                <title>GoCare Litoral | Planos de Saúde na Baixada Santista</title>
                <meta name="description"
                    content="Conheça o melhor e mais barato plano de saúde da Baixada Santista. Contrate online GoCare Saúde: serviços médicos de qualidade e atendimento humanizado com carência reduzida para o Litoral. Garanta agora mais saúde e bem-estar com facilidade e conveniência." />
            </Helmet>

            <Carousel />
            <BenefitsCarousel />

        </div>
    )
}

export default Home;