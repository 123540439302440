import React from 'react';
import { Helmet } from 'react-helmet';
import imgcartao from '../../../../assets/img-main/infopage/Cartao.png';
import imgcartao_mobile from '../../../../assets/img-main/infopage/Cartao-mobile.png';
import './Cartao.css';

const CartaoBeneficiario = () => (
    <>
        <Helmet>
            <title>Cartão de Beneficiário Digital | GoCare Litoral</title>
            <meta name="description" content="Acesse seu cartão de beneficiário digital diretamente pelo nosso app, sempre disponível para facilitar o seu atendimento." />
        </Helmet>
        <section className="cartao-page">
            <picture className="cartao-page__img">
                <source media="(min-width: 768px)" srcSet={imgcartao} />
                <source media="(max-width: 767px)" srcSet={imgcartao_mobile} />
                <img src={imgcartao} alt="Cartão de Beneficiário Digital" style={{ maxWidth: '100%', height: 'auto' }} />
            </picture>

            <h1>Cartão de Beneficiário Digital</h1>
            <p>Acesse seu cartão de beneficiário digital diretamente pelo nosso app, sempre disponível para facilitar o seu atendimento.</p>

            <a className="cartao-page_button" href="/aplicativo">
                <button>
                    Baixe o App para Acessar
                </button>
            </a>
        </section>
    </>
);

export default CartaoBeneficiario;
