import React from 'react';
import './CityModal.css';

const CityModal = ({ city, isCityOpen, onCityClose }) => {
    if (!isCityOpen) return null;

    return (
        <div className="citymodal-overlay">
            <div className="citymodal-content">
                <button onClick={onCityClose} className="close-button">Fechar</button>
                <h2>{city.nome} - Rede Credenciada - GoCare Litoral</h2>
                <div className="citymodal-table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Especialidade</th>
                                <th>Estabelecimento</th>
                                <th>Endereço</th>
                                <th>Telefones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {city.redes.map((item, index) => (
                                <React.Fragment key={index}>
                                    {item.estabelecimentos.map((estabelecimento, estIndex) => (
                                        <tr key={estIndex}>
                                            {estIndex === 0 && (
                                                <td className='especialidade' data-label="Especialidade" rowSpan={item.estabelecimentos.length}>
                                                    {item.especialidade}
                                                </td>
                                            )}
                                            <td data-label="Estabelecimento">{estabelecimento.nome}</td>
                                            <td data-label="Endereço">{estabelecimento.endereco}</td>
                                            <td data-label="Telefones">{estabelecimento.telefones.join(', ')}</td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    );
};

export default CityModal;
