import { Link, useLocation } from 'react-router-dom';
import './NewNavOptions.css';

const NewNavOptions = () => {
    const location = useLocation();

    return (
        <div className='header__bottom'>

            <nav className="bottom__menu__nav">

                <ul className="bottom__menu__ul">

                    <li className="bottom__menu__li">
                        <Link to="/autorizacao" className={`bottom__header__link ${location.pathname === '/autorizacao' ? 'active' : ''}`}>
                            <span className="bottom__hover">Autorização</span>
                        </Link>
                    </li>

                    <li className="bottom__menu__li">
                        <Link to="/cartao-beneficiario" className={`bottom__header__link ${location.pathname === '/cartao-beneficiario' ? 'active' : ''}`}>
                            <span className="bottom__hover">Cartão de Beneficiário</span>
                        </Link>
                    </li>

                    <li className="bottom__menu__li">
                        <Link to="/consultas" className={`bottom__header__link ${location.pathname === '/consultas' ? 'active' : ''}`}>
                            <span className="bottom__hover">Consultas</span>
                        </Link>
                    </li>

                    <li className="bottom__menu__li">
                        <Link to="/rede" className={`bottom__header__link ${location.pathname === '/rede' ? 'active' : ''}`}>
                            <span className="bottom__hover">Rede Credenciada</span>
                        </Link>
                    </li>

                    <li className="bottom__menu__li">
                        <Link to="/telemedicina" className={`bottom__header__link ${location.pathname === '/telemedicina' ? 'active' : ''}`}>
                            <span className="bottom__hover">Telemedicina</span>
                        </Link>
                    </li>

                    <li className="bottom__menu__li">
                        <Link to="/aplicativo" className={`bottom__header__link ${location.pathname === '/aplicativo' ? 'active' : ''}`}>
                            <span className="bottom__hover">Baixe o App</span>
                        </Link>
                    </li>

                </ul>

            </nav>

        </div>
    );
};

export default NewNavOptions;
